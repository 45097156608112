/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { FindListOptions } from '../../../app/core/data/request.models';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { BitstreamMetadataExtractionEntry } from '../shared/bitstream-metadata-extraction-entry.model';
import { BITSTREAM_METADATA_EXTRACTION_ENTRY } from '../shared/bitstream-metadata-extraction-entry.resource-type';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { map, switchMap } from 'rxjs/operators';

/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<BitstreamMetadataExtractionEntry> {
  protected linkPath = 'bitstreammetadataextractions';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<BitstreamMetadataExtractionEntry>) {
    super();
  }
}

/**
 * A service responsible for fetching/sending data from/to the REST API for entries on the bitstreammetadataextractions endpoint
 */
@Injectable()
@dataService(BITSTREAM_METADATA_EXTRACTION_ENTRY)
export class BitstreamMetadataExtractionsEntryDataService {
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<BitstreamMetadataExtractionEntry>) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }

  findByHref(href: string, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtractionEntry>[]): Observable<RemoteData<BitstreamMetadataExtractionEntry>> {
    return this.dataService.findByHref(href, true, true, ...linksToFollow);
  }

  findAllByHref(href: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtractionEntry>[]): Observable<RemoteData<PaginatedList<BitstreamMetadataExtractionEntry>>> {
    return this.dataService.findAllByHref(href, findListOptions, true, true, ...linksToFollow);
  }

  /**
   * Find a list of {@link BitstreamMetadataExtractionEntry}s providing the ID of the extraction to use and the ID of the bitstream to extract metadata from
   * @param extractionId    ID of the extraction to use
   * @param bitstreamId     ID of the bitstream to extract metadata from
   * @param findListOptions Options for the paginated list
   * @param linksToFollow   List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findByBitstream(extractionId: string, bitstreamId: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtractionEntry>[]): Observable<RemoteData<PaginatedList<BitstreamMetadataExtractionEntry>>> {
    return this.dataService.getBrowseEndpoint().pipe(
      map((href) => `${href}/${extractionId}/entries?bitstream=${bitstreamId}`),
      switchMap((href) => this.findAllByHref(href, findListOptions, ...linksToFollow))
    );
  }

  /**
   * Find a specific {@link BitstreamMetadataExtractionEntry} providing the ID of the extraction and the entry's ID
   * @param extractionId  ID of the extraction to use
   * @param entryId       ID of entry to retrieve
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findById(extractionId: string, entryId: string, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtractionEntry>[]): Observable<RemoteData<BitstreamMetadataExtractionEntry>> {
    return this.dataService.getBrowseEndpoint().pipe(
      map((href) => `${href}/${extractionId}/entryValues/${entryId}`),
      switchMap((href) => this.findByHref(href, ...linksToFollow))
    );
  }
}
