<ng-container *ngVar="(breadcrumbs$ | async) as breadcrumbs">
    <div class=container>
        <nav *ngIf="(showBreadcrumbs$ | async)" aria-label="breadcrumb" class="nav-breadcrumb">
            <ol class="container breadcrumb">
                <ng-container
                        *ngTemplateOutlet="breadcrumbs?.length > 0 ? breadcrumb : activeBreadcrumb; context: {text: 'home.breadcrumbs', url: '/'}"></ng-container>
                <ng-container *ngFor="let bc of breadcrumbs; let last = last;">
                    <ng-container *ngTemplateOutlet="!last ? breadcrumb : activeBreadcrumb; context: bc"></ng-container>
                </ng-container>
            </ol>
        </nav>

        <ng-template #breadcrumb let-text="text" let-url="url">
            <li class="breadcrumb-item"><div class="breadcrumb-item-limiter"><a [routerLink]="url" class="text-truncate">{{text | translate}}</a></div></li>
        </ng-template>

        <ng-template #activeBreadcrumb let-text="text">
            <li class="breadcrumb-item active" aria-current="page"><div class="breadcrumb-item-limiter"><div class="text-truncate">{{text | translate}}</div></div></li>
        </ng-template>
    </div>
</ng-container>

