/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { hasValue } from '../../../app/shared/empty.util';
import { AvsConversionService } from './avs-conversion.service';
import { AvsConversion } from './avs-conversion.model';

@Component({
  selector: 'avs-conversion-info',
  styleUrls: [],
  templateUrl: './avs-conversion-info.component.html',
})
/**
 * Avs conversion status component for editing a bitstream
 */
export class AvsConversionInfoComponent implements OnInit {

  @Input() avsConversion: AvsConversion;
  minimumBitrate: string;
  detectedBitrate: string;
  minimumSampleRate: string;
  detectedSampleRate: string;
  suffix: string;
  showForceButton: boolean;

  constructor(
    private avsConversionService: AvsConversionService,
  ) {
  }

  /**
   * Setup component for the given AVS conversion status
   */
  ngOnInit(): void {
    if (hasValue(this.avsConversion) && hasValue(this.avsConversion.info)) {
      [
        this.minimumBitrate,
        this.minimumSampleRate,
        this.detectedBitrate,
        this.detectedSampleRate,
      ] = this.avsConversion.info.split(';');

      const noData = '(no data)';
      if (this.detectedBitrate === '-1') {
        this.detectedBitrate = noData;
      }
      if (this.detectedSampleRate === '-1') {
        this.detectedSampleRate = noData;
      }

      if (hasValue(this.detectedSampleRate) && this.detectedSampleRate !== noData) {
        this.suffix = 'audio';
      } else if (hasValue(this.detectedBitrate) && this.detectedBitrate !== noData) {
        this.suffix = 'video';
      }

      this.showForceButton = this.avsConversion.status === 'SUCCEEDED';
    }
  }

  /**
   * Request a full conversion bypassing limit checks
   */
  forceConvert() {
    this.avsConversionService.forceConvert(this.avsConversion);
  }

}
