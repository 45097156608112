import { GetRequest } from '../core/data/request.models';

/**
 * The GET request for statlets
 */
export class StatletRequest extends GetRequest {
  constructor(uuid: string, href: string) {
    super(uuid, href);
  }

}
