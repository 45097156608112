/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MetadataSuggestionDifference } from './metadata-suggestion-difference.model';

/**
 * A model class for suggestions of metadata differences
 */
export class MetadataSuggestionDifferences {
  /**
   * The difference for one metadata field
   */
  [key: string]: MetadataSuggestionDifference;
}
