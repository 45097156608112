/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ResourceType } from '../../app/core/shared/resource-type';

/**
 * The resource type for the MediaInfo
 */
export const MEDIA_INFO = new ResourceType('media-info');
