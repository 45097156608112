import { autoserialize, inheritSerialization } from 'cerialize';
import { typedObject } from '../../cache/builders/build-decorators';
import { WORKFLOW_STEP } from './workflow-step.resource-type';
import { DSpaceObject } from '../../shared/dspace-object.model';

/**
 * A model class for a workflow step
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class WorkflowStep extends DSpaceObject {
  static type = WORKFLOW_STEP;

  /**
   * The workflow step's identifier
   */
  @autoserialize
  id: string;
}
