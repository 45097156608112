/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../app/core/core.module';
import { SharedModule } from '../../app/shared/shared.module';
import { AvsPlayerComponent } from './avs-player.component';
import { AvsService } from './avs.service';
import { AvsPlayerVideoComponent } from './avs-player-video.component';
import { AvsPlayerPlaylistComponent } from './avs-player-playlist.component';
import { AvsConfigFormsComponent } from './config/avs-config-forms.component';
import { AvsConfigService } from './config/avs-config.service';
import { DefaultAvsConfigComponent } from './config/default-avs-config.component';
import { AvsConversionService } from './status/avs-conversion.service';
import { AvsConversionComponent } from './status/avs-conversion.component';
import { AvsConversionInfoComponent } from './status/avs-conversion-info.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule.forRoot(),
  ],
  declarations: [
    AvsPlayerComponent,
    AvsPlayerVideoComponent,
    AvsPlayerPlaylistComponent,
    AvsConfigFormsComponent,
    DefaultAvsConfigComponent,
    AvsConversionComponent,
    AvsConversionInfoComponent,
  ],
  providers: [
  ],
  entryComponents: [],
  exports: [
    AvsPlayerComponent,
    AvsConfigFormsComponent,
  ],
})
/**
 * This module exports the AVS media player
 */
export class AvsPlayerModule {
}
