<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex w-100">
  <div class="flex-grow-1">
    <div class="font-weight-bold">{{ getDisplayName() }}</div>
    <div class="mb-1">
      <ds-atmire-metadata-values *ngIf="object.hasMetadata('dc.contributor.author')" [mdValues]="object.allMetadata('dc.contributor.author')" [separator]="'; '" [spacer]="false"></ds-atmire-metadata-values>
      <ds-atmire-metadata-uri-values *ngIf="object.hasMetadata('dc.identifier.uri')" [mdValues]="[object.firstMetadata('dc.identifier.uri')]" [spacer]="false"></ds-atmire-metadata-uri-values>
    </div>
    <div class="mb-1 extra-metadata" *ngIf="metadataCollapsed">
      <div *ngFor="let metadata of extraMetadata">
        <div *ngIf="metadata.getMetadataValues(object).length > 0" [class.mb-2]="metadata.spacer">
          <span *ngIf="metadata.label">{{metadata.label | translate}}: </span>
          <span *ngFor="let mdValue of metadata.getMetadataValues(object); let last=last;">
            {{mdValue.value}}<span *ngIf="!last" [innerHTML]="metadata.separator"></span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="hasExtraMetadata">
    <i class="collapse-button mb-1 flex-column fas"
       [ngClass]="metadataCollapsed ? 'fa-chevron-up' : 'fa-chevron-down'"
       (click)="metadataCollapsed = !metadataCollapsed"></i>
  </div>
</div>
