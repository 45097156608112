/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { getLiveImportMetadataSuggestionsForSubmissionObjectRoute } from '../../../import/import-routing-paths';
import { SubmissionService } from '../../../../app/submission/submission.service';
import { SubmissionScopeType } from '../../../../app/core/submission/submission-scope-type';
import { WorkspaceItem } from '../../../../app/core/submission/models/workspaceitem.model';
import { WorkflowItem } from '../../../../app/core/submission/models/workflowitem.model';

@Component({
  selector: 'ds-submission-form-import',
  templateUrl: './submission-form-import.component.html',
  styleUrls: ['./submission-form-import.component.scss']
})
export class SubmissionFormImportComponent implements OnInit {

  /**
   * The submission id
   * @type {string}
   */
  @Input() submissionId: string;

  /**
   * Route to the import page built from the submission item's ID and type
   */
  importRoute: string;

  constructor(private submissionService: SubmissionService) { }

  ngOnInit() {
    const scope = this.submissionService.getSubmissionScope();
    let submissionType;
    if (scope === SubmissionScopeType.WorkspaceItem) {
      submissionType = WorkspaceItem.type.value;
    } else {
      submissionType = WorkflowItem.type.value;
    }
    this.importRoute = getLiveImportMetadataSuggestionsForSubmissionObjectRoute(submissionType, this.submissionId);
  }

}
