/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
export const SAVED_ITEM_LISTS_MODULE_PATH = 'my-lists';

export function getSavedItemListsModuleRoute() {
  return `/${SAVED_ITEM_LISTS_MODULE_PATH}`;
}

export const ATMIRE_CUA_MODULE_PATH = 'atmire-cua';

export function getAtmireCuaModulePath() {
  return `/${ATMIRE_CUA_MODULE_PATH}`;
}
export const IMPORT_MODULE_PATH = 'imports';

export function getImportModulePath() {
  return `/${IMPORT_MODULE_PATH}`;
}
