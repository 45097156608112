<div class="d-flex flex-row">
  <ds-themed-item-page-title-field class="mr-auto" [item]="object">
  </ds-themed-item-page-title-field>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'person.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/person-placeholder.svg'"
                    [alt]="'thumbnail.person.alt'"
                    [placeholder]="'thumbnail.person.placeholder'">
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['person.email']"
      [label]="'person.page.email'">
    </ds-themed-generic-item-page-field>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
      <!--[fields]="['person.identifier.orcid']"-->
      <!--[label]="'person.page.orcid'">-->
    <!--</ds-themed-generic-item-page-field>-->
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['person.birthDate']"
      [label]="'person.page.birthdate'">
    </ds-themed-generic-item-page-field>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
      <!--[fields]="['person.identifier.staffid']"-->
      <!--[label]="'person.page.staffid'">-->
    <!--</ds-themed-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPerson'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPerson'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['person.jobTitle']"
      [label]="'person.page.jobtitle'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['person.familyName']"
      [label]="'person.page.lastname'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['person.givenName']"
      [label]="'person.page.firstname'">
    </ds-themed-generic-item-page-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.doi']"
      [label]="'item.page.doi'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.source.uri']"
      [label]="'item.source.page.uri'">
    </ds-item-page-uri-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isAuthorOfPublication',
                                          filter: 'isAuthorOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<div class="col-sm-12">
  <div class="btn-group" ngbDropdown>
    <button id="button-basic" ngbDropdownToggle type="button"
            class="btn btn-outline-primary float-left mr-2 dropdown-toggle"
            aria-controls="dropdown-basic"
            (click)="$event.preventDefault()"
    >
      {{"statlet.show" | translate}} <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" ngbDropdownMenu class="dropdown-menu"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem">
        <a class="dropdown-item"
           [routerLink]="'/atmire-cua/top/items'"
           [queryParams]="{isAuthorOfPublication:object.id}">
          {{"atmire.cua.top.header.items"|translate}}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item"
           [routerLink]="'/atmire-cua/top/countries'"
           [queryParams]="{isAuthorOfPublication:object.id}">
          {{"atmire.cua.top.header.countries"|translate}}
        </a>
      </li>
    </ul>
  </div>
</div>
