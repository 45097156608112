<div class="category-field" *ngIf="item?.allMetadata(fields).length > 0">
  <h5>{{label | translate}}</h5>
  <div class="simple-view-element" *ngFor="let category of categories">
    <div class="simple-view-element-body">
      <a href="{{category.link}}">
          <span class="dont-break-out">
            {{category.value}}
        </span>
      </a>
    </div>
  </div>
</div>
