import {autoserialize, autoserializeAs, deserialize} from 'cerialize';
import {typedObject} from '../cache/builders/build-decorators';
import {CacheableObject} from '../cache/object-cache.reducer';
import { HALLink } from './hal-link.model';
import {excludeFromEquals} from '../utilities/equals.decorators';
import {ResourceType} from './resource-type';
import {MANIFEST_OBJECT} from './manifest-object-reource-type';


/**
 * An abstract model class for a Batch Object.
 */
@typedObject
export class ManifestObject implements CacheableObject {

  static type = MANIFEST_OBJECT;
  /**
   * A string representing the BatchObject
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;
  @autoserialize
  public id;
  @autoserializeAs(String)
  public dsoId: string;
  @autoserialize
  public archivedDate: string;
  @autoserialize
  public modifiedDate: string;
  @autoserialize
  public metadata: object;
  @autoserialize
  public relationships: object;
  @autoserialize
  public policies: object;
  @autoserialize
  public bundles: object;
  @deserialize
  _links: {
    self: HALLink;
  };

  /**
   * A shorthand to get this batch's self link
   */
  get self(): string {
    return this._links.self.href;
  }

  /**
   * A shorthand to set this DSpaceObject's self link
   */
  set self(v: string) {
    this._links.self = {
      href: v
    };
  }
}
