<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>
  <div class="modal-header">{{'dso-selector.'+ action + '.' + objectType.toString().toLowerCase() + '.head' | translate}}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body" *ngVar="(importing$ | async) as importing">
    <ds-collection-dropdown *ngIf="!importing" (selectionChange)="navigate($event.collection)">
    </ds-collection-dropdown>
    <ds-loading *ngIf="importing"></ds-loading>
  </div>
</div>
