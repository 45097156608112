/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AbstractMetadataImportEntryListElementComponent } from '../abstract-metadata-import-entry-list-element.component';
import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { MetadataSuggestionEntry } from '../../../../core/shared/metadata-suggestion-entry.model';
import { ViewMode } from '../../../../../app/core/shared/view-mode.model';
import { ImportEntryListElementMetadataConfig } from '../import-entry-list-element-metadata-config.model';

@Component({
  selector: 'ds-metadata-suggestion-entry-list-element',
  styleUrls: ['../abstract-metadata-import-entry-list-element.component.scss'],
  templateUrl: '../abstract-metadata-import-entry-list-element.component.html'
})
/**
 * Component representing list element for a {@link MetadataSuggestionEntry}
 */
@listableObjectComponent(MetadataSuggestionEntry, ViewMode.ListElement)
export class MetadataSuggestionEntryListElementComponent extends AbstractMetadataImportEntryListElementComponent<MetadataSuggestionEntry> {
  /**
   * Get the list of extra metadata values to display in the collapsable area
   */
  getExtraMetadata(): ImportEntryListElementMetadataConfig[] {
    return [
      new ImportEntryListElementMetadataConfig('person.familyName', 'familyName'),
      new ImportEntryListElementMetadataConfig('person.givenName', 'givenName'),
      new ImportEntryListElementMetadataConfig('dc.description.abstract', 'abstract'),
      new ImportEntryListElementMetadataConfig('dc.subject', 'subject', false, ', '),
    ];
  }

  /**
   * Get the display name of the entry
   */
  getDisplayName(): string {
    return this.object.display;
  }
}
