/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Operation } from 'fast-json-patch';

/**
 * Transform a string operation into a fast-json-patch {@link Operation}
 * @param operationString The raw string of the operation
 * @param valueString     The value to attach to the operation
 */
export function stringOperationToPatchOperation(operationString: string, valueString: string): Operation {
  // Retrieve the patch operation from the first part of the operation string
  const op = operationString.substring(0, operationString.indexOf('/'));
  // Retrieve the patch path from the second part of the operation string
  let path = operationString.substring(operationString.indexOf('/'), operationString.length);
  // Patch operations aren't able to interpret '-' at the end of the path, so trim that part out
  if (path.indexOf('/-') > -1) {
    path = path.substring(0, path.indexOf('/-'));
  }
  const singleValue = { value: valueString };
  const value = [ singleValue ];

  return {
    op,
    path,
    value: op === 'replace' ? singleValue : value,
  } as Operation;
}
