/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { METADATA_SUGGESTION_ENTRY } from './metadata-suggestion-entry.resource-type';
import { HALLink } from '../../../app/core/shared/hal-link.model';

/**
 * Class representing a DSpace MetadataSuggestionEntry
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class MetadataSuggestionEntry extends DSpaceObject {
  static type = METADATA_SUGGESTION_ENTRY;

  /**
   * The identifier of this metadata suggestion entry
   */
  @autoserialize
  id: string;

  /**
   * The display name of this metadata suggestion entry
   */
  @autoserialize
  display: string;

  /**
   * The value of this metadata suggestion entry
   */
  @autoserialize
  value: string;

  @deserialize
  _links: {
    differences: HALLink;
    self: HALLink;
  };
}
