<div class="item" [id]="dso.id">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     rel="noopener noreferrer"
     [routerLink]="[itemPageRoute]" class="lead">
  </a>
  <div class="data">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
       [routerLink]="[itemPageRoute]" class="lead"
       [innerHTML]="dsoTitle"></a>
    <span *ngIf="linkType == linkTypes.None"
          class="lead"
          [innerHTML]="dsoTitle"></span>
    <ds-truncatable [id]="dso.id" class="text-muted">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <ds-truncatable-part [id]="dso.id" [minLines]="3" class="author">
        <span *ngIf="dso.allMetadata(['dc.description']).length > 0" class="item-list-org-unit-description">
          <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
                        [innerHTML]="firstMetadataValue('dc.description')"></span>
          </ds-truncatable-part>
        </span>
      </ds-truncatable-part>
    </ds-truncatable>
  </div>
</div>
