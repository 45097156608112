import { Component, Input } from '@angular/core';
import { ThemedComponent } from '../../../../../shared/theme-support/themed.component';
import { GenericItemPageFieldComponent } from './generic-item-page-field.component';
import { Item } from '../../../../../core/shared/item.model';

@Component({
  selector: 'ds-themed-generic-item-page-field',
  styleUrls: [],
  templateUrl: '../../../../../shared/theme-support/themed.component.html',
})
/**
 * This component can be used to represent metadata on a simple item page.
 * It is the most generic way of displaying metadata values
 * It expects 4 parameters: The item, a separator, the metadata keys and an i18n key
 */
export class ThemedGenericItemPageFieldComponent extends ThemedComponent<GenericItemPageFieldComponent> {

  @Input() item: Item;
  @Input() separator: string;
  @Input() fields: string[];
  @Input() label: string;
  @Input() renderHTML = false;

  protected inAndOutputNames: (keyof GenericItemPageFieldComponent & keyof this)[] = [
    'item',
    'separator',
    'fields',
    'label',
    'renderHTML',
  ];

  protected getComponentName(): string {
    return 'GenericItemPageFieldComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../../../../themes/${themeName}/app/item-page/simple/field-components/specific-field/generic/generic-item-page-field.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./generic-item-page-field.component`);
  }
}
