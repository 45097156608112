/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ResponseParsingService } from '../../../app/core/data/parsing.service';
import { Injectable } from '@angular/core';
import { RestRequest } from '../../../app/core/data/request.models';
import { RawRestResponse } from '../../../app/core/dspace-rest/raw-rest-response.model';
import { ParsedResponse } from '../../../app/core/cache/response.models';
import { MetadataSuggestionDifferences } from '../shared/metadata-suggestion-differences.model';

@Injectable({
  providedIn: 'root',
})
/**
 * A ResponseParsingService used to parse DSpaceRESTV2Response coming from the REST API to a
 * MetadataSuggestionDifferencesSuccessResponse containing MetadataSuggestionDifferences
 */
export class MetadataSuggestionDifferencesResponseParsingService implements ResponseParsingService {
  parse(request: RestRequest, data: RawRestResponse): ParsedResponse {
    const payload = data.payload;
    const differences = Object.assign(new MetadataSuggestionDifferences(), payload.differences);
    return new ParsedResponse(data.statusCode, undefined, differences);
  }
}
