<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(avsConfig$ | async) as avsConfigRD">
  <ng-container *ngIf="avsConfigRD?.hasSucceeded">

    <div class="form-group">
      <label class="">{{i18nPrefix + 'media-player' | translate}}</label>
      <div class="radio">
        <label>
          <input name="enableStreaming" type="radio" value="player-only"
                 [checked]="avsConfig.playerType === 'player-only'"
                 (change)="setNewPlayerType('player-only')">
          {{i18nPrefix + 'player-only' | translate}}
        </label>
      </div>
      <div class="radio">
        <label>
          <input name="enableStreaming" type="radio" value="player-and-streaming"
                 [checked]="avsConfig.playerType === 'player-and-streaming'"
                 (change)="setNewPlayerType('player-and-streaming')">
          {{i18nPrefix + 'player-and-streaming' | translate}}
        </label>
      </div>
      <div class="radio">
        <label>
          <input name="enableStreaming" type="radio" value="disabled"
                 [checked]="avsConfig.playerType === 'disabled'"
                 (change)="setNewPlayerType('disabled')">
          {{i18nPrefix + 'disabled' | translate}}
        </label>
      </div>
      <p class="text-muted">
        {{i18nPrefix + 'type.help1' | translate}}
      </p>
      <p class="text-muted">
        {{i18nPrefix + 'type.help2' | translate}}
      </p>
    </div>

    <div class="form-group">
      <label class="">{{i18nPrefix + 'media-player-permission' | translate}}</label>
      <div class="radio">
        <label>
          <input name="streamingPolicy" type="radio" value="public"
                 [checked]="avsConfig.playerPermissions === 'public'"
                 (change)="setNewPlayerPermissions('public')">
          {{i18nPrefix + 'public' | translate}}
        </label>
      </div>
      <div class="radio">
        <label>
          <input name="streamingPolicy" type="radio" value="admin" checked=""
                 [checked]="avsConfig.playerPermissions === 'admin'"
                 (change)="setNewPlayerPermissions('admin')">
          {{i18nPrefix + 'admin-only' | translate}}
        </label>
      </div>
      <p class="text-muted">
        {{i18nPrefix + 'permission.help' | translate}}
      </p>
    </div>
  </ng-container>
</ng-container>

<ng-content></ng-content>
