<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ds-atmire-metadata-field-wrapper [label]="label | translate" [spacer]="spacer">
  <a class="dont-break-out" *ngFor="let mdValue of mdValues; let last=last;" [href]="mdValue.value">
    {{ linktext || mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
  </a>
</ds-atmire-metadata-field-wrapper>
