/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { BitstreamDataService } from '../../../app/core/data/bitstream-data.service';
import { Observable } from 'rxjs';
import { Bitstream } from '../../../app/core/shared/bitstream.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { find, switchMap, tap } from 'rxjs/operators';
import { hasValue } from '../../../app/shared/empty.util';
import { MultipartPostRequest } from '../../../app/core/data/request.models';
import { Injectable } from '@angular/core';

@Injectable()
export class AtmireBitstreamDataService extends BitstreamDataService {
  /**
   * Create a temporary bitstream without a bundle
   * @param file
   * @param metadata
   */
  public postTemporaryBitstream(file, metadata): Observable<RemoteData<Bitstream>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint();

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('properties', JSON.stringify({name: file.name, metadata: metadata}));
          const request = new MultipartPostRequest(requestId, href, formData);

          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<Bitstream>(requestId)),
    );

  }

}
