import { Point } from './point.model';
import { Statlet } from './statlet.model';
import * as moment from 'moment';
import { hasValue } from '../shared/empty.util';
import { WidthCategory } from '../shared/host-window.service';

function getStatletConfig(statlet: Statlet) {
  return Object.assign({
      type: 'table',
      sort: (points: Point[]) => points,
      labelFormat: (viewport: WidthCategory, label: string) => label,
      columns: fixedGrid({xs: 12, sm: 12, md: 12, lg: 12, xl: 12}),
      legendPosition: (viewport: WidthCategory) => 'right'
    },
    statletConfig[statlet.id]
  );
}

const statletConfig = {
  AllTimeUsage: {
    type: 'donut',
    columns: largeAndAbove(4),
    legendPosition: donutLegendPosition
  },
  LastSixMonthsUsagePerMonth: {
    type: 'line-chart',
    sort: sortByLabels(),
    labelFormat: formatDatePerViewport(lineChartDateFormats),
  },
  Past6MonthItemByViews: {
    type: 'table',
    sort: sortByViews()
  },
  Past6MonthItemByDownloads: {
    type: 'table',
    sort: sortByDownloads()
  },
  Past6MonthTopCommunitiesByViews: {
    type: 'table',
    sort: sortByViews(),
    columns: largeAndAbove(4)
  },
  Past6MonthTopCommunitiesByDownloads: {
    type: 'table',
    sort: sortByDownloads(),
    columns: largeAndAbove(4)
  },
  Past6MonthAuthorsByViews: {
    type: 'table',
    sort: sortByViews(),
    columns: largeAndAbove(4)
  },
  Past6MonthAuthorsByDownloads: {
    type: 'table',
    sort: sortByDownloads(),
    columns: largeAndAbove(4)
  },
  Past6MonthCountriesByViews: {
    type: 'table',
    sort: sortByViews(),
    columns: largeAndAbove(4)
  },
  Past6MonthCountriesByDownloads: {
    type: 'table',
    sort: sortByDownloads(),
    columns: largeAndAbove(4)
  },
  Past6MonthWorldMapDownloads: {
    type: 'world-map',
    sort: sortByDownloads(),
  },
};

/**
 * Returns the statlet type to a statlet
 * @param statlet The given statlet
 */
export function getStatletType(statlet: Statlet): string {
  return getStatletConfig(statlet).type;
}

/**
 * Returns the data points of a statlet in the order that makes sense
 * @param statlet The given statlet
 */
export function getSortedDataPoints(statlet: Statlet): Point[] {
  const sortFn = getStatletConfig(statlet).sort;
  return sortFn([...statlet.points]);
}

function sortByComparing(compareFn: ((a: Point, b: Point) => number)) {
  return (points: Point[]) => [...points].sort(compareFn);
}

function sortByLabels() {
  return sortByComparing((a: Point, b: Point) => a.label.localeCompare(b.label));
}

function sortByDownloads() {
  return sortByComparing((a: Point, b: Point) => b.values.downloads - a.values.downloads);
}

function sortByViews() {
  return sortByComparing((a: Point, b: Point) => b.values.views - a.values.views);
}

/**
 * Formats the label according to the configured format
 * @param statlet The statlet this label is from
 * @param viewport The current viewport
 * @param label The label to be formatted
 */
export function getFormattedLabel(statlet: Statlet, viewport: WidthCategory, label: string): string {
  const formatFn = getStatletConfig(statlet).labelFormat;
  return formatFn(viewport, label);
}

function formatDate(formatString: string) {
  return (viewport: WidthCategory, dateString: string) =>
    moment.parseZone(dateString).format(formatString);
}

function formatDatePerViewport(formatPerViewport: (viewport: WidthCategory) => string) {
  return (viewport: WidthCategory, dateString: string) =>
    moment.parseZone(dateString).format(formatPerViewport(viewport));
}

function lineChartDateFormats(viewport: WidthCategory): string {
  switch (viewport) {
    case WidthCategory.XL:
    case WidthCategory.LG:
    case WidthCategory.MD:
    case WidthCategory.SM:
      return 'MMM YYYY';
    case WidthCategory.XS:
    default:
      return 'MM/YY';
  }
}

/**
 * Returns all the classes for the bootstrap grid for a given statlet
 * @param statlet
 */
export function getColumnClasses(statlet: Statlet): string {
  return getStatletConfig(statlet).columns;
}

function mediumAndAbove(columns: number) {
  return fixedGrid({xs: 12, sm: 12, md: columns, lg: columns, xl: columns});
}

function largeAndAbove(columns: number) {
  return fixedGrid({xs: 12, sm: 12, md: 12, lg: columns, xl: columns});
}

function fixedGrid(columns): string {
  const viewports = ['xs', 'sm', 'md', 'lg', 'xl'];
  const prefixes = ['col-', 'col-sm-', 'col-md-', 'col-lg-', 'col-xl-'];
  return viewports
    .map((viewport: string, index: number) => prefixes[index] + getColumns(columns, viewport))
    .join(' ');
}

function getColumns(config, viewport: string): number {
  let columns: number;
  if (hasValue(config[viewport])) {
    columns = config[viewport];
  } else if (hasValue(config.xs)) {
    columns = config.xs;
  } else {
    columns = 12;
  }
  return columns;
}

/**
 * Place the legend right or below based on the viewport
 * @param statlet
 * @param viewport
 */
export function getLegendPosition(statlet: Statlet, viewport: WidthCategory) {
  const legendPositionFn = getStatletConfig(statlet).legendPosition;
  return legendPositionFn(viewport);
}

function donutLegendPosition(viewport: WidthCategory) {
  return 'below';
  // switch (viewport) {
  //   case WidthCategory.XL:
  //   case WidthCategory.LG:
  //   case WidthCategory.MD:
  //     return 'below';
  //   case WidthCategory.SM:
  //   case WidthCategory.XS:
  //   default:
  //     return 'right';
  // }
}
