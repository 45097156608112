import { Component, Input, OnInit } from '@angular/core';

import { Item } from '../../../../core/shared/item.model';
import { fadeInOut } from '../../../animations/fade';
import { MyDspaceItemStatusType } from '../../../object-collection/shared/badges/my-dspace-status-badge/my-dspace-item-status-type';
import { SearchResult } from '../../../search/models/search-result.model';
import { Observable } from 'rxjs';
import { DSONameService } from '../../../../core/breadcrumbs/dso-name.service';

/**
 * This component show metadata for the given item object in the list view.
 */
@Component({
  selector: 'ds-item-list-preview',
  styleUrls: ['item-list-preview.component.scss'],
  templateUrl: 'item-list-preview.component.html',
  animations: [fadeInOut]
})
export class ItemListPreviewComponent implements OnInit {

  /**
   * The item to display
   */
  @Input() item: Item;

  /**
   * The search result object
   */
  @Input() object: SearchResult<any>;

  /**
   * Represent item's status
   */
  @Input() status: MyDspaceItemStatusType;

  /**
   * The ID of the workflow step this Item is currently at (if applicable).
   */
  @Input() stepID$?: Observable<string>;

  /**
   * A boolean representing if to show submitter information
   */
  @Input() showSubmitter = false;

  dsoTitle: string;

  constructor(
    private dsoNameService: DSONameService,
  ) {
  }

  ngOnInit(): void {
    this.dsoTitle = this.dsoNameService.getName(this.item);
  }

}
