import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { dataService } from '../cache/builders/build-decorators';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { CoreState } from '../core.reducers';
import { DataService } from '../data/data.service';
import { RequestService } from '../data/request.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { MultipartPostRequest, PatchRequest, PostRequest } from '../data/request.models';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { DSOChangeAnalyzer } from '../data/dso-change-analyzer.service';
import { WorkspaceItem } from './models/workspaceitem.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../data/remote-data';
import { find, map, switchMap, tap } from 'rxjs/operators';
import { hasValue } from '../../shared/empty.util';
import { HttpOptions } from '../dspace-rest/dspace-rest.service';
import { Operation } from 'fast-json-patch';
import { FindListOptions } from '../data/request.models';
import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import { RequestParam } from '../cache/models/request-param.model';
import { TranslateService } from '@ngx-translate/core';

/**
 * A service that provides methods to make REST requests with workspaceitems endpoint.
 */
@Injectable()
@dataService(WorkspaceItem.type)
export class WorkspaceitemDataService extends DataService<WorkspaceItem> {
  protected linkPath = 'workspaceitems';
  protected searchByItemLinkPath = 'item';

  constructor(
    protected comparator: DSOChangeAnalyzer<WorkspaceItem>,
    protected halService: HALEndpointService,
    protected http: HttpClient,
    protected notificationsService: NotificationsService,
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected store: Store<CoreState>,
    protected translate: TranslateService,) {
    super();
  }

  /**
   * Return the WorkspaceItem object found through the UUID of an item
   *
   * @param uuid           The uuid of the item
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param options        The {@link FindListOptions} object
   * @param linksToFollow  List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  public findByItem(uuid: string, useCachedVersionIfAvailable = false, reRequestOnStale = true, options: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<WorkspaceItem>[]): Observable<RemoteData<WorkspaceItem>> {
    const findListOptions = new FindListOptions();
    findListOptions.searchParams = [new RequestParam('uuid', encodeURIComponent(uuid))];
    const href$ = this.getSearchByHref(this.searchByItemLinkPath, findListOptions, ...linksToFollow);
    return this.findByHref(href$, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }


  /*
  * Creates a new workspace item for a parent collection
  * @param parentCollection
  */
  createNewWorkSpaceItem(parentCollection: string): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const request = new PostRequest(requestId, href);
          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId)),
    );
  }

  /**
   * Creates a new workspace item with a file for a parent collection
   * @param parentCollection
   * @param file
   */
  createNewWorkSpaceItemWithFile(parentCollection: string, file): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const formData = new FormData();
          formData.append('file', file);
          const request = new MultipartPostRequest(requestId, href, formData);

          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId)),
    );

  }

  /**
   * Creates a new workspace item from an imported entry for a parent collection
   * The imported entry is supplied via its uri (selflink)
   * @param parentCollection  The ID of the collection to create the new workspace item in
   * @param entryUri          The uri or selflink of the imported entry
   */
  createNewWorkSpaceItemFromImportEntry(parentCollection: string, entryUri: string): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const options: HttpOptions = Object.create({});
          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'text/uri-list');
          options.headers = headers;
          const request = new PostRequest(requestId, href, entryUri, options);
          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId)),
    );
  }
}
