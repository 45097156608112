<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="item" [id]="dso.id" *ngIf="object !== undefined && object !== null">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     rel="noopener noreferrer"
     [routerLink]="[itemPageRoute]" class="lead item-list-title dont-break-out">
  </a>
  <div class="data">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
       rel="noopener noreferrer"
       [routerLink]="[itemPageRoute]" class="lead"
       [routerLink]="isRTSFind() ? ['/admin/rts-history/' + dso.id] : ['/items/' + dso.id]" class="lead"
       [innerHTML]="firstMetadataValue('dc.title')"></a>
    <span *ngIf="linkType == linkTypes.None" class="lead item-list-title dont-break-out"
          [innerHTML]="dsoTitle"></span>
    <ds-truncatable [id]="dso.id" class="text-muted">
      <ds-truncatable-part [id]="dso.id" [minLines]="1" class="author">
        <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <ng-container *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">
          (<span *ngIf="dso.firstMetadataValue('dc.publisher')" class="item-list-publisher"
                 [innerHTML]="firstMetadataValue('dc.publisher') + ', &nbsp;'"></span>
          <span *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date"
                [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)<p>&nbsp;</p>
        </ng-container>
        <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0"
              class="item-list-authors">
          <span
            *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
              <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
              <span *ngIf="!last">; </span>
          </span>
        </span>
      </ds-truncatable-part>
    </ds-truncatable>
    <ds-truncatable [id]="dso.id" *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
      <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
        [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
      </ds-truncatable-part>
    </ds-truncatable>
  </div>
</div>
