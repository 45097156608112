/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SubmissionObject } from '../../app/core/submission/models/submission-object.model';
import { getImportModulePath } from '../atmire-app-routing-paths';

export const BITSTREAMS_ROUTE = 'bitstreams';

export function getImportBitstreamMetadataRoute(bitstreamId: string) {
  return `${getImportModulePath()}/${BITSTREAMS_ROUTE}/${bitstreamId}`;
}

export const PREVIEW_ROUTE = 'preview';
export const PREVIEW_EXTRACTION_ROUTE = 'extraction';
export const PREVIEW_EXTERNAL_SOURCES_ROUTE = 'external';

export function getImportBitstreamMetadataPreviewRoute(bitstreamId: string, extractionId: string, entryId: string) {
  return `${getImportModulePath()}/${PREVIEW_ROUTE}/${PREVIEW_EXTRACTION_ROUTE}/${bitstreamId}/${extractionId}/` + encodeURIComponent(entryId);
}

export function getImportExternalSourcePreviewRoute(sourceId: string, entryId: string) {
  return `${getImportModulePath()}/${PREVIEW_ROUTE}/${PREVIEW_EXTERNAL_SOURCES_ROUTE}/` + encodeURIComponent(sourceId) + `/${entryId}`;
}

export const METADATA_SUGGESTIONS_ROUTE = 'metadata-suggestions';

export function getLiveImportMetadataSuggestionsRoute() {
  return `${getImportModulePath()}/${METADATA_SUGGESTIONS_ROUTE}`;
}

export function getLiveImportMetadataSuggestionsForSubmissionObjectRoute(submissionType: string, itemId: string) {
  return `${getLiveImportMetadataSuggestionsRoute()}/${submissionType}/${itemId}`;
}

export const EXTERNAL_SOURCES_ROUTE = 'external-sources';

export function getLiveImportExternalSourcesRoute() {
  return `${getImportModulePath()}/${EXTERNAL_SOURCES_ROUTE}`;
}

export const LIVE_IMPORT_METADATA_SUGGESTIONS_DIFFERENCES_ROUTE = 'differences';

export function getLiveImportMetadataSuggestionsDifferencesRoute(suggestion: string, entry: string, submissionObject: SubmissionObject) {
  return `${getLiveImportMetadataSuggestionsRoute()}/${LIVE_IMPORT_METADATA_SUGGESTIONS_DIFFERENCES_ROUTE}/${suggestion}/` + encodeURIComponent(entry) + `/${submissionObject.type}/${submissionObject.id}`;
}
