/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MetadataValue } from '../../../../app/core/shared/metadata.models';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';

/**
 * A class containing information about metadata to display of a metadata import entry
 */
export class ImportEntryListElementMetadataConfig {
  /**
   * The metadata key(s) in scope
   * Wildcards are supported
   */
  keys: string | string[];

  /**
   * The i18n key for the label to display in front of the metadata values
   */
  label: string;

  /**
   * Whether or not to only retrieve the first metadata value of the entry
   */
  first = true;

  /**
   * The separator to use between multiple metadata values (this only makes sense if property 'first' is false)
   */
  separator: string;

  /**
   * Whether or not to include a spacer (margin) at the bottom
   */
  spacer = false;

  constructor(keys: string | string[], labelSuffix: string, first: boolean = false, separator?: string, spacer: boolean = false) {
    this.keys = keys;
    this.label = 'import.general.entry.metadata.' + labelSuffix;
    this.first = first;
    this.spacer = spacer;
    this.separator = separator;
  }

  /**
   * Get the list of metadata values to display for the configured field
   * @param entry The {@Link DSpaceObject} to retrieve metadata from
   */
  getMetadataValues(entry: DSpaceObject): MetadataValue[] {
    if (this.first) {
      return [entry.firstMetadata(this.keys)];
    } else {
      return entry.allMetadata(this.keys);
    }
  }
}
