/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MenuResolver } from '../app/menu.resolver';
import { MenuService } from '../app/shared/menu/menu.service';
import { BrowseService } from '../app/core/browse/browse.service';
import { AuthorizationDataService } from '../app/core/data/feature-authorization/authorization-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ScriptDataService } from '../app/core/data/processes/script-data.service';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { MenuID, MenuItemType } from '../app/shared/menu/initial-menus-state';
import { LinkMenuItemModel } from '../app/shared/menu/menu-item/models/link.model';
import { AuthService } from '../app/core/auth/auth.service';
import { StatisticsMenuService } from './shared/menu/statistics-menu/statistics-menu.service';
import { TextMenuItemModel } from '../app/shared/menu/menu-item/models/text.model';
import { FeatureID } from '../app/core/data/feature-authorization/feature-id';

/**
 * Menu entries specific to app-atmire are defined here.
 */
@Injectable()
export class AtmireMenuResolver extends MenuResolver {
  constructor(
    protected authService: AuthService,
    protected menuService: MenuService,
    protected browseService: BrowseService,
    protected authorizationService: AuthorizationDataService,
    protected modalService: NgbModal,
    protected scriptDataService: ScriptDataService,
    protected statisticsMenuService: StatisticsMenuService,
  ) {
    super(menuService, browseService, authorizationService, modalService, scriptDataService);
  }

  public createPublicMenu$(): Observable<boolean> {
    this.createStatisticsMenu();
    this.authService.isAuthenticated().subscribe((authenticated) => {
      if (environment.atmire.savedItemLists.anonymous || authenticated) {
        this.menuService.addSection(MenuID.PUBLIC, {
          id: 'my_lists',
          active: false,
          visible: true,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.my_lists',
            link: `/my-lists`
          } as LinkMenuItemModel,
          index: 2,
          shouldPersistOnRouteChange: true,
        });
      }
    });

    return super.createPublicMenu$();
  }

  createMainMenuSections(): void {
    super.createMainMenuSections();
    this.createCuaMenuSections();
  }

  createExportMenuSections(): void {
    super.createExportMenuSections();
  }

  createImportMenuSections(): void {
    super.createImportMenuSections();
  }

  createSiteAdministratorMenuSections(): void {
    super.createSiteAdministratorMenuSections();
  }

  createAccessControlMenuSections(): void {
    super.createAccessControlMenuSections();
  }

  createStatisticsMenu() {
    this.statisticsMenuService.addMenuOptionsForType('Site', true);
  }

  /**
   * Create menu sections dependent on whether or not the current user is a CUA administrator
   */
  createCuaMenuSections() {
    this.authorizationService.isAuthorized(FeatureID.CUAAdministratorOf).subscribe((isCUAAdmin: boolean) => {
      const menuList = [
        /* CUA Statistics */
        {
          id: 'atmire-cua_storage',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_storage',
            link: 'atmire-cua/storage'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua_search',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_search',
            link: 'atmire-cua/search'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua_usage',
          parentID: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.LINK,
            text: 'menu.section.atmire-cua_usage',
            link: 'atmire-cua/usage'
          } as LinkMenuItemModel
        },
        {
          id: 'atmire-cua',
          active: false,
          visible: isCUAAdmin,
          model: {
            type: MenuItemType.TEXT,
            text: 'menu.section.atmire-cua'
          } as TextMenuItemModel,
          icon: 'chart-bar',
          index: 12
        }
      ];

      menuList.forEach((menuSection) => this.menuService.addSection(MenuID.ADMIN, Object.assign(menuSection, {
        shouldPersistOnRouteChange: true
      })));
    });
  }
}
