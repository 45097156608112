<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(statusRD$ | async) as statusRD">
  <ng-container *ngIf="statusRD?.hasSucceeded">
    <ng-container *ngVar="statusRD.payload as avsstatus">
      <div class="container">
        <p>{{"bitstream.avs.conversion." + avsstatus.status.toLowerCase() | translate}}</p>
        <avs-conversion-info [avsConversion]="avsstatus">
        </avs-conversion-info>
        <ng-container *ngIf="avsstatus.status === 'SUCCEEDED'">
          <p>
            <button class="btn btn-danger" (click)="unconvert(avsstatus)">{{"bitstream.avs.conversion.delete-streaming-files" | translate}}</button>
          </p>
          <p>
            <button class="btn btn-danger" (click)="reconvert(avsstatus)">{{"bitstream.avs.conversion.reconvert" | translate}}</button>
          </p>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
