/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { hasValue } from '../../../app/shared/empty.util';
import { WorkspaceitemDataService } from '../../../app/core/submission/workspaceitem-data.service';
import { find } from 'rxjs/internal/operators/find';
import { RemoteData } from '../../../app/core/data/remote-data';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BitstreamDataService } from '../../../app/core/data/bitstream-data.service';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';
import { Bitstream } from '../../../app/core/shared/bitstream.model';
import { getImportBitstreamMetadataRoute, getLiveImportExternalSourcesRoute } from '../../import/import-routing-paths';
import { DSpaceObjectType } from '../../../app/core/shared/dspace-object-type.model';
import { AtmireBitstreamDataService } from '../../core/data/atmire-bitstream-data.service';
import { AtmireWorkspaceitemDataService } from '../../core/submission/atmire-workspaceitem-data.service';

/**
 * This component represents the submission section of the My DSpace Component
 */
@Component({
  selector: 'ds-atmire-my-dspace-new-submission',
  styleUrls: ['./atmire-my-dspace-new-submission.component.scss'],
  templateUrl: './atmire-my-dspace-new-submission.component.html'
})
export class AtmireMyDspaceNewSubmissionComponent {

  /**
   * The file that is provided by the input/drop zone
   */
  private file;

  /**
   * Reference to the modal being opened to select a collection
   */
  private modalRef: NgbModalRef;

  /**
   * Behavior subject to keep track of selected collection UUID in the modal
   */
  private collectionUuid$: BehaviorSubject<string> = new BehaviorSubject(null);

  /**
   * The types of DSpaceObject's ds-dso-selector is listing
   */
  objectTypes = [DSpaceObjectType.COLLECTION];

  /**
   * Initialize instance variables
   *
   * @param {NotificationsService} notificationsService
   * @param {WorkspaceitemDataService} workSpaceService
   * @param {BitstreamDataService} bitstreamService
   * @param {TranslateService} translate
   * @param {Router} router
   * @param {NgbModal} modalService
   */
  constructor(private notificationsService: NotificationsService,
              private workSpaceService: AtmireWorkspaceitemDataService,
              private bitstreamService: AtmireBitstreamDataService,
              private translate: TranslateService,
              private router: Router,
              private modalService: NgbModal
  ) {
  }

  /**
   * Set the file based on the provided event
   * @param event
   */
  setFile(event) {
    this.file = event;
  }

  /**
   * Opens the modal to select a collection
   * @param modal
   */
  openModal(modal) {
    this.modalRef = this.modalService.open(modal);
  }

  /**
   * Start a submission from scratch.
   * This will open the modal to select a collection. The chosen collection will be used to create a new workspace item
   * On success the user is redirected to the created workspace item
   *
   * @param modal
   */
  startScratchSubmission(modal) {
    this.openModal(modal);

    this.collectionUuid$.pipe(
      find((collectionUUID) => hasValue(collectionUUID))
    ).subscribe((collectionUUID) => {
      this.workSpaceService.createNewWorkSpaceItem(collectionUUID)
        .subscribe((rd) => {
          if (rd.hasSucceeded) {
            this.router.navigate(['workspaceitems', rd.payload.id, 'edit']);
          } else {
            this.notificationsService.error(this.translate.get('atmire-my-dspace-new-submission.error.head'),
              rd.errorMessage);
          }
        });
    });
  }

  /**
   * Starts a submission with a file
   * This will open the modal to select a collection. The chosen collection and provided file will be used to create a new workspace item
   * On success the user is redirected to the created workspace item
   *
   * @param modal
   */
  submitWithFile(modal) {
    this.openModal(modal);
    this.collectionUuid$.pipe(
      find((collectionUUID) => hasValue(collectionUUID))
    ).subscribe((collectionUUID) => {
      this.workSpaceService.createNewWorkSpaceItemWithFile(collectionUUID, this.file)
        .subscribe((rd) => {
          if (rd.hasSucceeded) {
            this.router.navigate(['workspaceitems', rd.payload.id, 'edit']);
          } else {
            this.notificationsService.error(this.translate.get('atmire-my-dspace-new-submission.with-file.error.head'),
              rd.errorMessage);
          }
        });
    });
  }

  /**
   * Uploads a file for metadata import and redirects the user to the "select entry" page for the created bitstream
   */
  importMetadataFromFile() {
    const metadata = {
      'dc.description': [
        {
          value: 'Metadata import from file',
          language: null,
          authority: null,
          confidence: -1,
          place: 0
        }
      ]
    };

    this.bitstreamService.postTemporaryBitstream(this.file, metadata).pipe(getFirstCompletedRemoteData()).subscribe((rd: RemoteData<Bitstream>) => {
      if (rd.hasSucceeded) {
        this.notificationsService.success(this.translate.get('atmire-my-dspace-new-submission.temp-bitstream-upload.success.head'));
        console.log(getImportBitstreamMetadataRoute(rd.payload.id));
        this.router.navigate([getImportBitstreamMetadataRoute(rd.payload.id)]);
      } else if (rd.hasFailed) {
        this.notificationsService.error(this.translate.get('atmire-my-dspace-new-submission.temp-bitstream-upload.error.head'),
          rd.errorMessage);
      }
    });
  }

  /**
   * Start a live submission
   */
  startLiveImport() {
    this.router.navigate([getLiveImportExternalSourcesRoute()]);
  }

  /**
   * Submit the chosen collection as a value in the behavior subject
   * Closes the modal
   * @param $event
   */
  setCollection($event) {
    this.collectionUuid$.next($event.uuid);
    this.close();
  }

  /**
   * Checks whether a file has been provided
   */
  hasFile() {
    if (hasValue(this.file)) {
      return true;
    }
    return false;
  }

  /**
   * Closes the modal
   */
  close() {
    this.modalRef.close();
  }

}
