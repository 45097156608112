/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ItemPageResolver } from '../../app/item-page/item-page.resolver';
import { AuthenticatedGuard } from '../../app/core/auth/authenticated.guard';
import { ItemBreadcrumbResolver } from '../../app/core/breadcrumbs/item-breadcrumb.resolver';
import { UploadBitstreamComponent } from '../../app/item-page/bitstreams/upload/upload-bitstream.component';
import { ITEM_EDIT_PATH, UPLOAD_BITSTREAM_PATH } from '../../app/item-page/item-page-routing-paths';
import { ThemedItemPageComponent } from '../../app/item-page/simple/themed-item-page.component';
import { ThemedFullItemPageComponent } from '../../app/item-page/full/themed-full-item-page.component';
import {
  BitstreamRequestACopyPageComponent
} from '../../app/shared/bitstream-request-a-copy-page/bitstream-request-a-copy-page.component';
import { REQUEST_COPY_MODULE_PATH } from '../../app/app-routing-paths';

const routes: Routes = [
  {
    path: ':id',
    resolve: {
      dso: ItemPageResolver,
      breadcrumb: ItemBreadcrumbResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: ThemedItemPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'full',
        component: ThemedFullItemPageComponent,
      },
      {
        path: ITEM_EDIT_PATH,
        loadChildren: () => import('../../app/../app/item-page/edit-item-page/edit-item-page.module')
          .then((m) => m.EditItemPageModule),
      },
      {
        path: UPLOAD_BITSTREAM_PATH,
        component: UploadBitstreamComponent,
        canActivate: [AuthenticatedGuard]
      },
      {
        path: REQUEST_COPY_MODULE_PATH,
        component: BitstreamRequestACopyPageComponent,
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
})
export class AtmireItemPageRoutingModule {
}
