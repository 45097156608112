import {Observable} from 'rxjs/internal/Observable';
import {RTS_VERSION_OBJECT} from '../shared/rts-version-object.resource-type';
import {Injectable} from '@angular/core';
import {RTSVersionObject} from '../shared/rts-version-object-model';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';
import { RequestService } from './request.service';
import { DataService } from './data.service';
import { dataService } from '../cache/builders/build-decorators';
import { CoreState } from '../core.reducers';

/**
 * Service handling all RTS version object requests
 */
@Injectable({
  providedIn: 'root'
})
@dataService(RTS_VERSION_OBJECT)
export class RTSVersionObjectDataService extends DataService<RTSVersionObject> {
  protected linkPath = 'rts-versions';

      constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected store: Store<CoreState>,
              protected halService: HALEndpointService,
              protected objectCache: ObjectCacheService,
              protected notificationsService: NotificationsService,
              protected http: HttpClient,
              protected comparator: DefaultChangeAnalyzer<RTSVersionObject>) {
    super();
  }

  getBrowseEndpoint(options, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }
}
