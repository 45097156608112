/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { MetadataSuggestion } from '../shared/metadata-suggestion.model';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../app/core/data/dso-change-analyzer.service';
import { Injectable } from '@angular/core';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { METADATA_SUGGESTION } from '../shared/metadata-suggestion.resource-type';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { combineLatest, Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { FindListOptions } from '../../../app/core/data/request.models';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { map, switchMap } from 'rxjs/operators';
import { MetadataSuggestionEntryDataService } from './metadata-suggestion-entry-data.service';
import { LiveImportSearchResult } from '../shared/live-import-search-result.model';
import { getAllSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { MetadataSuggestionBased } from '../shared/metadata-suggestion-based.enum';
import { hasValue } from '../../../app/shared/empty.util';
import { SubmissionObject } from '../../../app/core/submission/models/submission-object.model';

/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<MetadataSuggestion> {
  protected linkPath = 'metadatasuggestions';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<MetadataSuggestion>) {
    super();
  }

  /**
   * Find the list of metadata suggestions for a given {@link SubmissionObject}
   * @param submissionObject  The {@link SubmissionObject}
   * @param options           Options to use for the paginated list
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findBySubmissionObject(submissionObject: SubmissionObject, options: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<RemoteData<PaginatedList<MetadataSuggestion>>> {
    return this.halService.getEndpoint(this.linkPath).pipe(
      switchMap((href) => this.findAllByHref(this.buildHrefFromFindOptions(href, options, [`${submissionObject.type}=${submissionObject.id}`], ...linksToFollow), {}, true, true, ...linksToFollow))
    );
  }
}

/**
 * A service responsible for fetching/sending data from/to the REST API on the metadatasuggestions endpoint
 */
@Injectable()
@dataService(METADATA_SUGGESTION)
export class MetadataSuggestionDataService {
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<MetadataSuggestion>,
    protected metadataSuggestionEntryService: MetadataSuggestionEntryDataService) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }

  findByHref(href: string, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<RemoteData<MetadataSuggestion>> {
    return this.dataService.findByHref(href, true, true, ...linksToFollow);
  }

  findAllByHref(href: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<RemoteData<PaginatedList<MetadataSuggestion>>> {
    return this.dataService.findAllByHref(href, findListOptions, true, true, ...linksToFollow);
  }

  findById(id: string, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<RemoteData<MetadataSuggestion>> {
    return this.dataService.findById(id, true, true, ...linksToFollow);
  }

  /**
   * Find the list of metadata suggestions for a given {@link SubmissionObject}
   * @param submissionObject  The {@link SubmissionObject}
   * @param options           Options to use for the paginated list
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findBySubmissionObject(submissionObject: SubmissionObject, options: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<RemoteData<PaginatedList<MetadataSuggestion>>> {
    return this.dataService.findBySubmissionObject(submissionObject, options, ...linksToFollow);
  }

  /**
   * Find the list of metadata suggestions for a given {@link SubmissionObject} and wrap
   * them in a search result object containing the total amount of entries for every metadata suggestion found
   * @param submissionObject  The {@link SubmissionObject}
   * @param query             The query used to list the amount of entries within each metadata suggestion
   * @param filterBy          Filter by what the {@link MetadataSuggestion} has to be based on by providing a {@link MetadataSuggestionBased} enum
   * @param options           Options to use for the paginated list of metadata suggestions
   * @param countOptions      Options to use for counting the paginated list of metadata suggestion entries
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  searchBySubmissionObject(submissionObject: SubmissionObject, query: string, filterBy?: MetadataSuggestionBased, options: FindListOptions = {}, countOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestion>[]): Observable<LiveImportSearchResult<MetadataSuggestion>[]> {
    return this.findBySubmissionObject(submissionObject, options, ...linksToFollow).pipe(
      getAllSucceededRemoteDataPayload(),
      switchMap((suggestions: PaginatedList<MetadataSuggestion>) => {
        return combineLatest(suggestions.page.filter((metadataSuggestion) => hasValue(filterBy) ? metadataSuggestion[filterBy] : true).map((suggestion: MetadataSuggestion) => {
          return this.metadataSuggestionEntryService.countEntriesForMetadataSuggestion(suggestion, submissionObject, query, countOptions).pipe(
            map((count: number) => new LiveImportSearchResult(suggestion, query, count))
          );
        }));
      })
    );
  }
}
