import { Component, Input, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BitstreamDataService } from '../../../../core/data/bitstream-data.service';

import { Bitstream } from '../../../../core/shared/bitstream.model';
import { Item } from '../../../../core/shared/item.model';
import { RemoteData } from '../../../../core/data/remote-data';
import { hasValue } from '../../../../shared/empty.util';
import { PaginatedList } from '../../../../core/data/paginated-list.model';
import { NotificationsService } from '../../../../shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { getFirstCompletedRemoteData } from '../../../../core/shared/operators';
import { AuthService } from '../../../../core/auth/auth.service';
import * as moment from 'moment';
import { AuthorizationDataService } from '../../../../core/data/feature-authorization/authorization-data.service';
import { FeatureID } from '../../../../core/data/feature-authorization/feature-id';
import { ResourcePolicyService } from '../../../../core/resource-policy/resource-policy.service';

/**
 * This component renders the file section of the item
 * inside a 'ds-metadata-field-wrapper' component.
 */
@Component({
  selector: 'ds-item-page-file-section',
  templateUrl: './file-section.component.html',
  styleUrls: ['./file-section.component.scss']
})
export class FileSectionComponent implements OnInit {

  @Input() item: Item;

  label = 'item.page.files';

  supplementalLabel = 'item.page.supplementalFiles';

  separator = '<br/>';

  bitstreams$: BehaviorSubject<Bitstream[]>;

  currentPage: number;

  isLoading: boolean;

  isLastPage: boolean;

  isAuthorized$: Observable<boolean>;

  show$: Observable<boolean>;

  pageSize = 5;

  constructor(
    protected bitstreamDataService: BitstreamDataService,
    protected authService: AuthService,
    protected notificationsService: NotificationsService,
    protected translateService: TranslateService,
    protected authorizationDataService: AuthorizationDataService,
    protected resourcePolicyService: ResourcePolicyService,
  ) {
  }

  ngOnInit(): void {
    this.getNextPage();
  }

  checkDate(embargoStartDate: string): boolean {
    const isAfter = moment().isAfter(embargoStartDate, 'day');
    const istoday = moment().isSame(embargoStartDate, 'day');

    return !(isAfter || istoday);
  }

  /**
   * This method will retrieve the next page of Bitstreams from the external BitstreamDataService call.
   * It'll retrieve the currentPage from the class variables and it'll add the next page of bitstreams with the
   * already existing one.
   * If the currentPage variable is undefined, we'll set it to 1 and retrieve the first page of Bitstreams
   */
  getNextPage(): void {
    this.isLoading = true;
    if (this.currentPage === undefined) {
      this.currentPage = 1;
      this.bitstreams$ = new BehaviorSubject([]);
    } else {
      this.currentPage++;
    }
    this.bitstreamDataService.findAllByItemAndBundleName(this.item, 'ORIGINAL', {
      currentPage: this.currentPage,
      elementsPerPage: this.pageSize
    }).pipe(
      getFirstCompletedRemoteData(),
    ).subscribe((bitstreamsRD: RemoteData<PaginatedList<Bitstream>>) => {
      if (bitstreamsRD.errorMessage) {
        this.notificationsService.error(this.translateService.get('file-section.error.header'), `${bitstreamsRD.statusCode} ${bitstreamsRD.errorMessage}`);
      } else if (hasValue(bitstreamsRD.payload)) {
        console.log(bitstreamsRD.payload);
        this.isAuthorized$ = this.authorizationDataService.isAuthorized(FeatureID.CanDownload, bitstreamsRD.payload.page[0]?.self);
        const current: Bitstream[] = this.bitstreams$.getValue();
        this.bitstreams$.next([...current, ...bitstreamsRD.payload.page]);
        this.isLoading = false;
        this.isLastPage = this.currentPage === bitstreamsRD.payload.totalPages;
      }
    });
  }

  isAuth(bitstream: Bitstream): Observable<boolean> {
    let isAuthed: Observable<boolean>;
    isAuthed = this.authorizationDataService.isAuthorized(FeatureID.CanDownload, bitstream.self);

    return isAuthed;
  }

  isAdmin(bitstream: Bitstream): Observable<boolean> {
    let isAdmin: Observable<boolean>;


    if (bitstream.hasMetadata('isadmin')) {
      if (bitstream.allMetadataValues('isadmin')[0].toLowerCase() === 'true') {
        isAdmin = new Observable(observer => observer.next(true));
      } else {
        isAdmin = new Observable(observer => observer.next(false));
      }
    }

    return isAdmin;
  }

  isEmbargo(bitstream: Bitstream): Observable<boolean> {
    let isEmbargo: Observable<boolean>;

    if (bitstream.hasMetadata('projection.date')) {
      const embargoDate = bitstream.allMetadataValues('projection.date')[0];
      isEmbargo = new Observable(observer => observer.next(this.checkDate(embargoDate)));
    } else {
      isEmbargo = new Observable(observer => observer.next(false));
    }

    return isEmbargo;
  }

  getEmbargoDate(bitstream: Bitstream): string {
    return bitstream.allMetadataValues('projection.date')[0];
  }
}
