/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { typedObject } from '../../app/core/cache/builders/build-decorators';
import { MEDIA_INFO } from './media-info.resource-type';
import { excludeFromEquals } from '../../app/core/utilities/equals.decorators';
import { autoserialize, deserialize } from 'cerialize';
import { ResourceType } from '../../app/core/shared/resource-type';
import { HALLink } from '../../app/core/shared/hal-link.model';

/**
 * Information necessary for the media player
 */
@typedObject
export class MediaInfo {
  static type = MEDIA_INFO;

  @autoserialize
  audios: MediaObject[];

  @autoserialize
  videos: MediaObject[];

  @deserialize
  _links: { self: HALLink };

  @excludeFromEquals
  @autoserialize
  type: ResourceType;
}

// tslint:disable-next-line:max-classes-per-file
export class MediaObject {
  direct: string;
  mimetype: string;
  dash: string;
  hls: string;
  id: string;
  name: string;
  order: number;
  poster: string;
}
