import { deserialize, deserializeAs, inheritSerialization } from 'cerialize';
import { inheritLinkAnnotations, link, typedObject } from '../../cache/builders/build-decorators';
import { IDToUUIDSerializer } from '../../cache/id-to-uuid-serializer';
import { WORKFLOWITEM } from '../../eperson/models/workflowitem.resource-type';
import { SubmissionObject } from './submission-object.model';
import { HALLink } from '../../shared/hal-link.model';
import { WORKFLOW_STEP } from '../../tasks/models/workflow-step.resource-type';
import { Observable } from 'rxjs';
import { RemoteData } from '../../data/remote-data';
import { WorkflowStep } from '../../tasks/models/workflow-step.model';

/**
 * A model class for a WorkflowItem.
 */
@typedObject
@inheritSerialization(SubmissionObject)
@inheritLinkAnnotations(SubmissionObject)
export class WorkflowItem extends SubmissionObject {
  static type = WORKFLOWITEM;

  /**
   * The universally unique identifier of this WorkflowItem
   * This UUID is generated client-side and isn't used by the backend.
   * It is based on the ID, so it will be the same for each refresh.
   */
  @deserializeAs(new IDToUUIDSerializer(WorkflowItem.type.value), 'id')
  uuid: string;

  /**
   * The {@link HALLink}s for this SubmissionObject
   */
  @deserialize
  _links: {
    self: HALLink;
    collection: HALLink;
    item: HALLink;
    submissionDefinition: HALLink;
    submitter: HALLink;
    step: HALLink;
  };

  /**
   * The task action type
   * Will be undefined unless the group {@link HALLink} has been resolved.
   */
  @link(WORKFLOW_STEP)
  step: Observable<RemoteData<WorkflowStep>>;
}
