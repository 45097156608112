/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { BrowseByDatePageComponent as BaseComponent } from '../../../../../app/browse-by/browse-by-date-page/browse-by-date-page.component';
import { ChangeDetectorRef, Component } from '@angular/core';
import { BrowseByDataType, rendersBrowseBy } from '../../../../../app/browse-by/browse-by-switcher/browse-by-decorator';
import { Observable } from 'rxjs';
import { SearchOptions } from '../../../../../app/shared/search/models/search-options.model';
import { ActivatedRoute, Router } from '@angular/router';
import { BrowseService } from '../../../../../app/core/browse/browse.service';
import { DSpaceObjectDataService } from '../../../../../app/core/data/dspace-object-data.service';
import { PaginationService } from '../../../../../app/core/pagination/pagination.service';
import { AuthorizationDataService } from '../../../../../app/core/data/feature-authorization/authorization-data.service';
import { hasValue } from '../../../../../app/shared/empty.util';
import { getFirstSucceededRemoteData } from '../../../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { COMMUNITY } from '../../../../../app/core/shared/community.resource-type';
import { SearchFilter } from '../../../../../app/shared/search/models/search-filter.model';
import { FeatureID } from '../../../../../app/core/data/feature-authorization/feature-id';
import { getCommunityPageRoute } from '../../../../../app/community-page/community-page-routing-paths';
import { getCollectionPageRoute } from '../../../../../app/collection-page/collection-page-routing-paths';

@Component({
  selector: 'ds-browse-by-date-page',
  styleUrls: ['../../../../../app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component.scss'],
  templateUrl: '../browse-by-metadata-page/browse-by-metadata-page.component.html'
})
@rendersBrowseBy(BrowseByDataType.Date, 'atmire')
/**
 * The contents of this themed-component are equal to that of themed {@link BrowseByMetadataPageComponent}
 * This is because this component needs access to both the modifications of its own BaseComponent, as well as
 * modifications of the themed version of its parent component.
 * Since we can't extend two classes, it's inevitable we need to copy modifications of one of the two.
 */
export class BrowseByDatePageComponent extends BaseComponent {
  /**
   * Search options to use for the export of the parent (only when a scope is present)
   */
  exportSearchOptions$: Observable<SearchOptions>;

  /**
   * Variables dependent on the parent's type
   */
  parentVariables$: Observable<any>;

  public constructor(protected route: ActivatedRoute,
                     protected browseService: BrowseService,
                     protected dsoService: DSpaceObjectDataService,
                     protected paginationService: PaginationService,
                     protected router: Router,
                     protected cdRef: ChangeDetectorRef,
                     protected authorizationDataService: AuthorizationDataService) {
    super(route, browseService, dsoService, router, paginationService, cdRef, authorizationDataService);
  }

  /**
   * Update the parent Community or Collection using their scope
   * @param scope   The UUID of the Community or Collection to fetch
   */
  updateParent(scope: string) {
    if (hasValue(scope)) {
      this.parent$ = this.dsoService.findById(scope).pipe(
        getFirstSucceededRemoteData()
      );
      this.exportSearchOptions$ = this.parent$.pipe(
        map((dsoRD) => {
          const type = (dsoRD.payload.type as any) === COMMUNITY.value ? 'comm' : 'coll';
          return new SearchOptions({
            filters: [
              new SearchFilter(`location.${type}`, [dsoRD.payload.id]),
            ],
          });
        })
      );
      this.parentVariables$ = this.parent$.pipe(
        switchMap((dsoRD) => {
          const type = (dsoRD.payload.type as any) === COMMUNITY.value ? 'community' : 'collection';
          return this.authorizationDataService.isAuthorized(type === 'community' ? FeatureID.IsCommunityAdmin : FeatureID.IsCollectionAdmin).pipe(
            map((isAuthorized) => {
              return {
                type: type,
                pageRoute: type === 'community' ? getCommunityPageRoute(dsoRD.payload.id) : getCollectionPageRoute(dsoRD.payload.id),
                editTooltipMsg: `${type}.page.edit`,
                isEditAuthorized: isAuthorized
              };
            })
          );
        })
      );
    }
  }
}
