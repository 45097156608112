import { autoserialize, deserialize, inheritSerialization } from 'cerialize';
import { typedObject } from '../cache/builders/build-decorators';
import { EXTERNAL_SOURCE_ENTRY } from './external-source-entry.resource-type';
import { HALLink } from './hal-link.model';
import { DSpaceObject } from './dspace-object.model';

/**
 * Model class for a single entry from an external source
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class ExternalSourceEntry extends DSpaceObject {
  static type = EXTERNAL_SOURCE_ENTRY;

  /**
   * Unique identifier
   */
  @autoserialize
  id: string;

  /**
   * The value to display
   */
  @autoserialize
  display: string;

  /**
   * The value to store the entry with
   */
  @autoserialize
  value: string;

  /**
   * The ID of the external source this entry originates from
   */
  @autoserialize
  externalSource: string;

  /**
   * The {@link HALLink}s for this ExternalSourceEntry
   */
  @deserialize
  _links: {
    self: HALLink;
  };
}
