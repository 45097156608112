<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div [ngSwitch]="suffix">
  <p *ngSwitchCase="'audio'">
    {{ 'bitstream.avs.conversion.low-quality-conversion.audio' | translate: {
    minimumBitrate: this.minimumBitrate,
    detectedBitrate: this.detectedBitrate,
    minimumSampleRate: this.minimumSampleRate,
    detectedSampleRate: this.detectedSampleRate
  }
    }}
  </p>
  <p *ngSwitchCase="'video'">
    {{ 'bitstream.avs.conversion.low-quality-conversion.video' | translate: {
    minimumBitrate: this.minimumBitrate,
    detectedBitrate: this.detectedBitrate
  }
    }}
  </p>
  <p *ngSwitchCase="null">
    {{this.avsConversion.info}}
  </p>
  <ng-container *ngIf="this.showForceButton">
    <ng-container *ngIf="this.avsConversion.forceButton">
      <p>
        <button class="btn btn-danger" (click)="forceConvert()">
          {{ 'bitstream.avs.conversion.force' | translate }}
        </button>
      </p>
      <p>{{ 'bitstream.avs.conversion.force.help.enabled' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="!this.avsConversion.forceButton">
      <p>
        <button class="btn btn-danger" disabled>
          {{ 'bitstream.avs.conversion.force' | translate }}
        </button>
      </p>
      <p>{{ 'bitstream.avs.conversion.force.help.disabled' | translate }}</p>
    </ng-container>
  </ng-container>
</div>
