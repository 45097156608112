/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { BitstreamMetadataExtraction } from '../shared/bitstream-metadata-extraction.model';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BITSTREAM_METADATA_EXTRACTION } from '../shared/bitstream-metadata-extraction.resource-type';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { FindListOptions } from '../../../app/core/data/request.models';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { getAllSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { map } from 'rxjs/operators';

/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<BitstreamMetadataExtraction> {
  protected linkPath = 'bitstreammetadataextractions';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<BitstreamMetadataExtraction>) {
    super();
  }
}

/**
 * A service responsible for fetching/sending data from/to the REST API on the bitstreammetadataextractions endpoint
 */
@Injectable()
@dataService(BITSTREAM_METADATA_EXTRACTION)
export class BitstreamMetadataExtractionDataService {
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<BitstreamMetadataExtraction>) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }

  findByHref(href: string, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtraction>[]): Observable<RemoteData<BitstreamMetadataExtraction>> {
    return this.dataService.findByHref(href, true, true, ...linksToFollow);
  }

  findAllByHref(href: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtraction>[]): Observable<RemoteData<PaginatedList<BitstreamMetadataExtraction>>> {
    return this.dataService.findAllByHref(href, findListOptions, true, true, ...linksToFollow);
  }

  findAll(options: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtraction>[]): Observable<RemoteData<PaginatedList<BitstreamMetadataExtraction>>> {
    return this.dataService.findAll(options, true, true, ...linksToFollow);
  }

  /**
   * Find all {@link BitstreamMetadataExtraction}s and return the result in the form of an array
   * This method doesn't support retrieving a specific amount of objects. It will always retrieve ALL at once.
   * @param options       Options for retrieving the list of {@link BitstreamMetadataExtraction}s.
   *                      The elementsPerPage will be overwritten by 9999 to ensure ALL objects are retrieved.
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findAllArray(options: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<BitstreamMetadataExtraction>[]): Observable<BitstreamMetadataExtraction[]> {
    return this.findAll(Object.assign({}, options, {
      elementsPerPage: 9999
    }), ...linksToFollow).pipe(
      getAllSucceededRemoteDataPayload(),
      map((extractionList: PaginatedList<BitstreamMetadataExtraction>) => extractionList.page)
    );
  }
}
