<ng-container *ngVar="(bitstreams$ | async) as bitstreams">
  <ng-container *ngIf="bitstreams?.length > 0">
    <div *ngIf="bitstreams[0]; let file">
      <ds-file-download-link *ngVar="(isAuthorized$ | async) as isAuth" [bitstream]="file" [item]="item">
        <button [ngClass]="{ 'btn': true, 'active': isAuth, 'inactive': !isAuth}" [disabled]="!isAuth">
          <i class="fas fa-download"></i>
          Download
        </button>
      </ds-file-download-link>
    </div>
  </ng-container>
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 0" [label]="label | translate">
    <div class="file-section">
      <ds-file-download-link [showLinks]="true" tooltip="{{file?.name}}" class="file-container" *ngFor="let file of bitstreams | slice:0:1; let last=last;" [bitstream]="file" [item]="item">
        <ng-container *ngIf="isAuth(file) | async">
          <span class="file-name">{{file?.name}}</span>
          <span class="file-size">({{(file?.sizeBytes) | dsFileSize }})</span>
          <div *ngIf="isEmbargo(file) | async" class="file-section">
            <br>
            <div>
              <span> File Embargoed Until: </span>
              <span>({{ getEmbargoDate(file) }})</span>
            </div>
          </div>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ng-container>
      </ds-file-download-link>
      <div class="file-container" *ngFor="let file of bitstreams; let last=last;">
        <ng-container *ngIf="!(isAuth(file) | async) && !(isAdmin(file) | async)">
          <span class="file-name">{{file?.name}}</span>
          <span class='file-size'>({{(file?.sizeBytes) | dsFileSize }})</span>
          <div *ngIf="isEmbargo(file) | async" class="file-section">
            <br>
            <div>
              <span> File Embargoed Until: </span>
              <span>({{ getEmbargoDate(file) }})</span>
            </div>
          </div>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ng-container>
      </div>
    </div>
  </ds-metadata-field-wrapper>
  <ds-metadata-field-wrapper *ngIf="bitstreams?.length > 1" [label]="supplementalLabel | translate">
    <div class="file-section">
      <ds-file-download-link [showLinks]="true" tooltip="{{file?.name}}" class="file-container" *ngFor="let file of bitstreams | slice:1; let last=last;" [bitstream]="file" [item]="item">
        <ng-container *ngIf="isAuth(file) | async">
          <span class="file-name">{{file?.name}}</span>
          <span class="file-size">({{(file?.sizeBytes) | dsFileSize }})</span>
          <div *ngIf="isEmbargo(file) | async" class="file-section">
            <br>
            <div>
              <span> File Embargoed Until: </span>
              <span>({{ getEmbargoDate(file) }})</span>
            </div>
          </div>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ng-container>
      </ds-file-download-link>
      <div class="file-container" *ngFor="let file of bitstreams; let last=last;">
        <ng-container *ngIf="!(isAuth(file) | async) && !(isAdmin(file) | async)">
          <span class="file-name">{{file?.name}}</span>
          <span class='file-size'>({{(file?.sizeBytes) | dsFileSize }})</span>
          <div *ngIf="isEmbargo(file) | async" class="file-section">
            <br>
            <div>
              <span> File Embargoed Until: </span>
              <span>({{ getEmbargoDate(file) }})</span>
            </div>
          </div>
          <span *ngIf="!last" innerHTML="{{separator}}"></span>
        </ng-container>
      </div>
      <ds-themed-loading *ngIf="isLoading" message="{{'loading.default' | translate}}" [showMessage]="false"></ds-themed-loading>
      <div *ngIf="!isLastPage" class="mt-1" id="view-more">
        <a class="bitstream-view-more btn btn-outline-secondary btn-sm" [routerLink]="" (click)="getNextPage()">{{'item.page.bitstreams.view-more' | translate}}</a>
      </div>
      <div *ngIf="isLastPage && currentPage != 1" class="mt-1" id="collapse">
        <a class="bitstream-collapse btn btn-outline-secondary btn-sm" [routerLink]="" (click)="currentPage = undefined; getNextPage();">{{'item.page.bitstreams.collapse' | translate}}</a>
      </div>
    </div>
  </ds-metadata-field-wrapper>
</ng-container>
