import {Observable} from 'rxjs/internal/Observable';
import {Injectable} from '@angular/core';
import {MANIFEST_OBJECT} from '../shared/manifest-object-reource-type';
import {ManifestObject} from '../shared/manifest-object.model';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';
import { RequestService } from './request.service';
import { DataService } from './data.service';
import { CoreState } from '../core.reducers';
import { dataService } from '../cache/builders/build-decorators';

/**
 * Service handling all RTS version object requests
 */
@Injectable({
  providedIn: 'root'
})
@dataService(MANIFEST_OBJECT)
export class RTSManifestObjectDataService extends DataService<ManifestObject> {
  protected linkPath = 'rts-versions';


    constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected store: Store<CoreState>,
              protected halService: HALEndpointService,
              protected objectCache: ObjectCacheService,
              protected notificationsService: NotificationsService,
              protected http: HttpClient,
              protected comparator: DefaultChangeAnalyzer<ManifestObject>) {
    super();
  }

  getBrowseEndpoint(options, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }
}
