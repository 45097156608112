/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { AVS_CONVERSION } from './avs-conversion.resource-type';
import { autoserialize, deserialize } from 'cerialize';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';
import { ResourceType } from '../../../app/core/shared/resource-type';

/**
 * The AVS conversion status of a bitstream
 */
@typedObject
export class AvsConversion implements CacheableObject {
  static type = AVS_CONVERSION;

  @autoserialize
  status: string;

  @autoserialize
  info: string;

  @autoserialize
  forceButton: boolean;

  @deserialize
  _links: { self: HALLink };

  @excludeFromEquals
  @autoserialize
  type: ResourceType;
}
