<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(dsoRD$ | async) as dsoRD">
  <atmire-avs-config-forms [submitForm]="formSubmissionHooks"
                           [i18nPrefix]="'avs.default.config.'"
                           [avsConfig$]="dsoRD?.payload?.atmireAvsConfig">
    <button type="submit" class="btn btn-primary" (click)="onSubmit()">
      {{'form.save' | translate}}
    </button>
  </atmire-avs-config-forms>
</ng-container>
