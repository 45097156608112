/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { AtmireAppRoutingModule } from './atmire-app-routing.module';
import {
  AtmireObjectCollectionModule
} from './atmire-object-collection/atmire-object-collection.module';
import { AtmireSavedItemListStoreService } from './atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { AtmireSavedItemListDataService } from './atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { LinkService } from '../app/core/cache/builders/link.service';
import { AtmireLinkService } from './core/cache/builders/atmire-link.service';
import { AtmireAuthService } from './core/auth/atmire-auth.service';
import { AtmireSharedModule } from './shared/atmire-shared.module';
import { AtmireItemDataService } from './core/data/atmire-item-data.service';
import { SearchTermsDataService } from './atmire-cua/search-reports/search-reports-service/search-terms-data.service';
import { SearchReportsDataService } from './atmire-cua/search-reports/search-reports-service/search-reports-data.service';
import { SharedStatletsModule } from './atmire-cua/statlets/shared/shared-statlets.module';
import { AtmireValuePairDataService } from './atmire-cua/shared-reports/value-pairs/atmire-value-pair-data.service';
import { AtmireSelectiveExportSharedModule } from './atmire-selective-export/atmire-selective-export-shared/atmire-selective-export-shared.module';
import { AtmireSavedItemListAuthenticatedGuard } from './atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-authenticated.guard';
import { AtmireMyDspaceNewSubmissionComponent } from './my-dspace-page/atmire-my-dspace-submission/atmire-my-dspace-new-submission.component';
import { AtmireBitstreamDataService } from './core/data/atmire-bitstream-data.service';
import { BitstreamMetadataExtractionDataService } from './core/data/bitstream-metadata-extraction-data.service';
import { BitstreamMetadataExtractionsEntryDataService } from './core/data/bitstream-metadata-extractions-entry-data.service';
import { ExternalSourceEntryDataService } from './core/data/external-source-entry-data.service';
import { MetadataSuggestionDataService } from './core/data/metadata-suggestion-data.service';
import { MetadataSuggestionDifferencesDataService } from './core/data/metadata-suggestion-differences-data.service';
import { MetadataSuggestionEntryDataService } from './core/data/metadata-suggestion-entry-data.service';
import { ImportEntryParentSelectorComponent } from './shared/dso-selector/modal-wrappers/import-entry-parent-selector/import-entry-parent-selector.component';
import { BitstreamMetadataExtractionEntryListElementComponent } from './shared/object-list/metadata-import-entry-list-element/bitstream-metadata-extraction-entry-list-element/bitstream-metadata-extraction-entry-list-element.component';
import { ExternalSourceEntryListElementComponent } from './shared/object-list/metadata-import-entry-list-element/external-source-entry-list-element/external-source-entry-list-element.component';
import { MetadataSuggestionEntryListElementComponent } from './shared/object-list/metadata-import-entry-list-element/metadata-suggestion-entry-list-element/metadata-suggestion-entry-list-element.component';
import { AbstractMetadataImportEntryListElementComponent } from './shared/object-list/metadata-import-entry-list-element/abstract-metadata-import-entry-list-element.component';
import { SubmissionFormImportComponent } from './submission/form/submission-form-import/submission-form-import.component';
import { ImportSharedModule } from './import/shared/import-shared.module';
import { AtmireWorkspaceitemDataService } from './core/submission/atmire-workspaceitem-data.service';
import { AtmireWorkflowItemDataService } from './core/submission/atmire-workflowitem-data.service';
import { AtmireExternalSourceService } from './core/data/atmire-external-source.service';
import { DSONameService } from '../app/core/breadcrumbs/dso-name.service';
import { AtmireDSONameService } from './core/breadcrumbs/atmire-dso-name.service';
import { avsModels } from './avs-player/avs-player.models';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
  ...avsModels
];

const DECLARATIONS = [
  AtmireMyDspaceNewSubmissionComponent,
  ImportEntryParentSelectorComponent,
  AbstractMetadataImportEntryListElementComponent,
  BitstreamMetadataExtractionEntryListElementComponent,
  ExternalSourceEntryListElementComponent,
  MetadataSuggestionEntryListElementComponent,
  SubmissionFormImportComponent,
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  BitstreamMetadataExtractionEntryListElementComponent,
  ExternalSourceEntryListElementComponent,
  MetadataSuggestionEntryListElementComponent,
];

const PROVIDERS = [
  AtmireBitstreamDataService,
  BitstreamMetadataExtractionDataService,
  BitstreamMetadataExtractionsEntryDataService,
  ExternalSourceEntryDataService,
  MetadataSuggestionDataService,
  MetadataSuggestionDifferencesDataService,
  MetadataSuggestionEntryDataService,
  AtmireWorkspaceitemDataService,
  AtmireWorkflowItemDataService,
  AtmireExternalSourceService,
  { provide: DSONameService, useClass: AtmireDSONameService },
  { provide: LinkService, useClass: AtmireLinkService },
  AtmireSavedItemListStoreService,
  AtmireSavedItemListDataService,
  AtmireAuthService,
  AtmireItemDataService,
  SearchTermsDataService,
  SearchReportsDataService,
  AtmireValuePairDataService,
  AtmireSavedItemListAuthenticatedGuard,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedStatletsModule,
    AtmireAppRoutingModule,
    AtmireObjectCollectionModule,
    ImportSharedModule,
    AtmireSharedModule,
    AtmireSelectiveExportSharedModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class AtmireAppModule {
}
