/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * Class representing a search result for a source on a Live Import page
 * This is a wrapper class to store the source together with the amount of available entries
 */
export class LiveImportSearchResult<T> {
  /**
   * The import source found
   */
  source: T;

  /**
   * The query used to determine the amount of results
   */
  query: string;

  /**
   * The amount of entries for this source and the active search query
   */
  results: number;

  constructor(source: T, query: string, results: number) {
    this.source = source;
    this.query = query;
    this.results = results;
  }
}
