/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * A media player for a given item
 */
import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../app/core/shared/item.model';
import { AvsService } from './avs.service';
import { MediaInfo } from './media-info.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../app/core/data/remote-data';

@Component({
  selector: 'atmire-avs-player',
  styleUrls: ['./avs-player.component.scss'],
  templateUrl: './avs-player.component.html',
})
/**
 * The media player for an item
 */
export class AvsPlayerComponent implements OnInit {
  @Input() item: Item;

  mediaInfo: Observable<RemoteData<MediaInfo>>;

  constructor(private service: AvsService) {
  }

  /**
   * Setup the component for the given item
   */
  ngOnInit(): void {
    this.mediaInfo = this.service.getMediaInfo(this.item);
  }
}
