import {ChangeDetectionStrategy, Component, Inject, OnInit, Renderer2} from '@angular/core';
import { ItemComponent } from '../shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import {DOCUMENT} from '@angular/common';
import {BitstreamDataService} from '../../../../core/data/bitstream-data.service';
import {BehaviorSubject} from 'rxjs';
import {RouteService} from '../../../../core/services/route.service';

/**
 * Component that represents a publication Item page
 */

@listableObjectComponent('Publication', ViewMode.StandalonePage)
@Component({
  selector: 'ds-publication',
  styleUrls: ['./publication.component.scss'],
  templateUrl: './publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends ItemComponent implements OnInit {

  doi: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private renderer2: Renderer2, @Inject(DOCUMENT) private _document, protected bitstreamDataService: BitstreamDataService, protected routeService: RouteService,) {
    super(routeService, bitstreamDataService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
    this.renderer2.appendChild(this._document.body, s);
    console.log(this.object.firstMetadataValue('dc.identifier.doi'));
    this.doi.next(this.object.firstMetadataValue('dc.identifier.doi'));
  }
}
