/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Item } from '../../../../../../../app/core/shared/item.model';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import {
  listableObjectComponent
} from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import {
  UntypedItemComponent as BaseComponent
} from '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component';

/**
 * Component that represents an untyped Item page
 *
 * This component uses the same template & style as PublicationComponent.
 * A separate template for untyped Items should only be made on client request.
 *
 * todo: this component is a placeholder to ensure client themes use the same HTML for publications and untyped Items.
 *       when implementing a new client theme:
 *          - copy the publication template & styles from the atmire theme (into ../publication)
 *          - point `templateUrl` and `styleUrls` to these files
 *          - uncomment the decorator
 */
// @listableObjectComponent(Item, ViewMode.StandalonePage, Context.Any, 'client')
@Component({
  selector: 'ds-untyped-item',
  styleUrls: [
    '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component.scss',
    // '../publication/publication.component.scss'
  ],
  templateUrl: '../../../../../../../app/item-page/simple/item-types/untyped-item/untyped-item.component.html',
  // templateUrl: '../publication/publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UntypedItemComponent extends BaseComponent {
}
