/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { MetadataSuggestionEntry } from '../shared/metadata-suggestion-entry.model';
import { Observable } from 'rxjs';
import { MetadataSuggestionDifferences } from '../shared/metadata-suggestion-differences.model';
import { GetRequest } from '../../../app/core/data/request.models';
import { getAllSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { WorkspaceItem } from '../../../app/core/submission/models/workspaceitem.model';
import { Operation } from 'fast-json-patch';
import { RequestService } from '../../../app/core/data/request.service';
import { WorkspaceitemDataService } from '../../../app/core/submission/workspaceitem-data.service';
import { stringOperationToPatchOperation } from '../metadata-suggestion/metadata-suggestion.utils';
import { RemoteData } from '../../../app/core/data/remote-data';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { GenericConstructor } from '../../../app/core/shared/generic-constructor';
import { ResponseParsingService } from '../../../app/core/data/parsing.service';
import { MetadataSuggestionDifferencesResponseParsingService } from './metadata-suggestion-differences-response-parsing.service';

/**
 * A service responsible for actions regarding {@link MetadataSuggestionDifferences}
 */
@Injectable()
export class MetadataSuggestionDifferencesDataService {

  constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected workspaceItemService: WorkspaceitemDataService) {
  }

  /**
   * Find the differences for a MetadataSuggestionEntry
   * @param suggestionEntry
   */
  findDifferences(suggestionEntry: MetadataSuggestionEntry): Observable<RemoteData<MetadataSuggestionDifferences>> {
    const requestId = this.requestService.generateRequestId();
    const href = suggestionEntry._links.differences.href;

    this.requestService.send(Object.assign(new GetRequest(requestId, href), {
      getResponseParser(): GenericConstructor<ResponseParsingService> {
        return MetadataSuggestionDifferencesResponseParsingService;
      }
    }));

    return this.rdbService.buildFromRequestUUID<MetadataSuggestionDifferences>(requestId);
  }

  /**
   * Apply all the differences from a metadata suggestion entry to a workspace item
   * @param suggestionEntry The entry to retrieve differences from
   * @param workspaceItem   The workspace item to apply the differences to
   */
  applyAllDifferences(suggestionEntry: MetadataSuggestionEntry, workspaceItem: WorkspaceItem): Observable<RemoteData<WorkspaceItem>> {
    return this.findDifferences(suggestionEntry).pipe(
      getAllSucceededRemoteDataPayload(),
      map((differences: MetadataSuggestionDifferences) => this.differencesToPatch(differences)),
      switchMap((patch) => this.workspaceItemService.patch(Object.assign(workspaceItem, { uuid: workspaceItem.id }), patch))
    );
  }

  /**
   * Iterate over all the suggestions within a MetadataSuggestionDifferences object and return them as an array of
   * operations (patch)
   * @param differences The differences to iterate over
   */
  differencesToPatch(differences: MetadataSuggestionDifferences): Operation[] {
    const patch: Operation[] = [];

    Object.keys(differences).forEach((key) => {
      const suggestions = differences[key].suggestions;
      suggestions.forEach((suggestion) => {
        const operations = suggestion.operations;
        operations.forEach((operation) => {
          patch.push(stringOperationToPatchOperation(operation, suggestion.newvalue));
        });
      });
    });

    return patch;
  }
}
