<header>
<div class="navContainer">
  <div id="IATopBar" class="row">
    <div class="container largeView">
      <span style="float: left">
        <a href="https://www.iastate.edu">iastate.edu</a>
        <a href="#">Index</a>
      </span>
      <span style="float: right">
        <a href="https://info.iastate.edu/">Directory</a>
        <a href="https://www.fpm.iastate.edu/maps/">Maps</a>
        <a href="https://web.iastate.edu/safety/">Safety</a>
        <a href="#">Sign Ons</a>
      </span>
    </div>
    <div class="container mobileView">
      <span class="col-12" style="float: left">
        <a href="https://www.iastate.edu">iastate.edu</a>
        <a href="#">Index</a>
        <a href="https://info.iastate.edu/">Directory</a>
      </span>
      <span class="col-12"  style="float: right">
        <a href="https://www.fpm.iastate.edu/maps/">Maps</a>
        <a class="topBarNavSafetyMobile" href="https://web.iastate.edu/safety/">Safety</a>
        <a class="topNavSignOnMobile" href="#">Sign Ons</a>
      </span>
    </div>
  </div>
  <div id="IABrandBar" class="row">
    <div class="container" >
      <div class="pl-2">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                aria-controls="collapsingNav"
                aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
          <span class="navBarText" aria-hidden="true">Menu</span>
        </button>
      </div>
      <nav class="navbar navbar-light navbar-expand-md float-right px-0 threeNav">
        <ds-lang-switch></ds-lang-switch>
        <ds-auth-nav-menu></ds-auth-nav-menu>
        <ds-impersonate-navbar></ds-impersonate-navbar>
      </nav>
      <a [routerLink]="'/home'">
        <span class="wordmark-isu">Iowa State University</span>
        <span class="wordmark-unit-title">Digital Repository</span>
      </a>
    </div>
  </div>
</div>
</header>
