/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Inject, OnDestroy } from '@angular/core';
import { DSOSelectorModalWrapperComponent, SelectorActionType } from '../../../../../app/shared/dso-selector/modal-wrappers/dso-selector-modal-wrapper.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { DSpaceObjectType } from '../../../../../app/core/shared/dspace-object-type.model';
import { DSpaceObject } from '../../../../../app/core/shared/dspace-object.model';
import { NotificationsService } from '../../../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { hasValue } from '../../../../../app/shared/empty.util';
import { BehaviorSubject, Subscription } from 'rxjs';
import { getFirstCompletedRemoteData } from '../../../../../app/core/shared/operators';
import { AtmireWorkspaceitemDataService } from '../../../../core/submission/atmire-workspaceitem-data.service';

@Component({
  selector: 'ds-import-entry-parent-selector',
  templateUrl: './import-entry-parent-selector.component.html'
})
/**
 * Component to wrap a list of existing collections inside a modal
 * Used to choose a collection from to create a new workspace item in, using the injected import entry
 */
export class ImportEntryParentSelectorComponent extends DSOSelectorModalWrapperComponent implements OnDestroy {
  objectType = DSpaceObjectType.ITEM;
  selectorType = DSpaceObjectType.COLLECTION;
  action = SelectorActionType.CREATE;

  importing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Array to track all subscriptions and unsubscribe them onDestroy
   * @type {Array}
   */
  protected subs: Subscription[] = [];

  constructor(@Inject('entry') protected entry: DSpaceObject,
              protected activeModal: NgbActiveModal,
              protected route: ActivatedRoute,
              protected router: Router,
              protected workspaceitemService: AtmireWorkspaceitemDataService,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService) {
    super(activeModal, route);
  }

  /**
   * Create a new workspace item from the import entry and navigate to submission (or display an error notification)
   */
  navigate(dso: DSpaceObject) {
    this.importing$.next(true);
    this.subs.push(
      this.workspaceitemService.createNewWorkSpaceItemFromImportEntry(dso.id, this.entry.self).pipe(
        getFirstCompletedRemoteData()
      ).subscribe((workspaceItemRD) => {
        if (workspaceItemRD.hasSucceeded) {
          this.router.navigate(['workspaceitems', workspaceItemRD.payload.id, 'edit']);
        } else if (workspaceItemRD.hasFailed) {
          this.notificationsService.error(this.translate.get('import.general.entry.error.head'),
            workspaceItemRD.errorMessage);
        }
        this.close();
      })
    );
  }

  /**
   * Unsub all subscriptions
   */
  ngOnDestroy(): void {
    this.subs.filter((sub) => hasValue(sub)).forEach((sub) => sub.unsubscribe());
  }

}
