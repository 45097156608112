/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MetadataFieldWrapperComponent } from '../../../../../../app/item-page/field-components/metadata-field-wrapper/metadata-field-wrapper.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-atmire-metadata-field-wrapper',
  styleUrls: ['../../../../../../app/item-page/field-components/metadata-field-wrapper/metadata-field-wrapper.component.scss'],
  templateUrl: './atmire-metadata-field-wrapper.component.html'
})
export class AtmireMetadataFieldWrapperComponent extends MetadataFieldWrapperComponent {
  /**
   * Whether or not to add spacing at the bottom of the component
   */
  @Input() spacer = true;
}
