import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {RequestService} from '../core/data/request.service';
import {RemoteDataBuildService} from '../core/cache/builders/remote-data-build.service';
import {HALEndpointService} from '../core/shared/hal-endpoint.service';
import {CacheableObject} from '../core/cache/object-cache.reducer';
import {RemoteData} from '../core/data/remote-data';
import {Statlet} from './statlet.model';
import {ensureArrayHasValue, isNotEmptyOperator} from '../shared/empty.util';
import {distinctUntilChanged} from 'rxjs/internal/operators/distinctUntilChanged';
import {map} from 'rxjs/internal/operators/map';
import {
  sendRequest, getFirstSucceededRemoteData,
  getRequestFromRequestHref,
  getResponseFromEntry, mapRemoteData
} from '../core/shared/operators';
import {StatletRequest} from './statlet-request';
import {RestRequest} from '../core/data/request.models';
import {HideStatletsAction, ShowStatletsAction} from '../../app-atmire/atmire-cua/statlets/shared/data/store/statlet.actions';
import {StatletState} from './statlet.reducers';
import {switchMap, tap} from 'rxjs/operators';
import {PaginatedList} from '../core/data/paginated-list.model';

/**
 * The statlet service
 */
@Injectable()
export class StatletService {
  /**
   * The name of the link to the statlets in the HAL response
   */
  protected linkPath = 'statlets';

  /**
   * Dependencies
   * @param store The app store
   * @param requestService The request service
   * @param halService The hal service
   * @param rdb The remote data builder service
   */
  constructor(
    protected store: Store<StatletState>,
    protected requestService: RequestService,
    protected halService: HALEndpointService,
    protected rdb: RemoteDataBuildService,
  ) {
  }

  /**
   * Request the statlet data (or use the cached responses)
   * and return a RemoteData Observable for them
   * @param object A CacheableObject which may or may not have statlets, usually a DSpaceObject
   */
  public getStatlets(object: CacheableObject): Observable<RemoteData<Statlet[]>> {
    const request$ = this.halService.getEndpoint(this.linkPath, object._links?.self?.href).pipe(
        isNotEmptyOperator(),
        distinctUntilChanged(),
        map((endpointURL: string) => new StatletRequest(this.requestService.generateRequestId(), endpointURL)),
    );
    request$.subscribe((request: RestRequest) => this.requestService.send(request, true));
    return request$.pipe(
        map((request: RestRequest) => request.href),
        switchMap((href) => this.rdb.buildSingle<PaginatedList<Statlet>>(href)),
        mapRemoteData((payload) => payload.page)
    );
  }

  /**
   * Dispatch a ShowStatletsAction
   */
  public showStatlets() {
    this.store.dispatch(new ShowStatletsAction());
  }

  /**
   * Dispatch a HideStatletsAction
   */
  public hideStatlets() {
    this.store.dispatch(new HideStatletsAction());
  }

}
