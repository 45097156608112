<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="simple-view-element" [class.mb-0]="!spacer" [class.d-none]="hideIfNoTextContent && content.textContent.trim().length === 0">
  <h5 class="simple-view-element-header" *ngIf="label">{{ label }}</h5>
  <div #content class="simple-view-element-body">
    <ng-content></ng-content>
  </div>
</div>
