<a [routerLink]="(bitstreamPath$| async)?.routerLink" class="dont-break-out" [queryParams]="(bitstreamPath$| async)?.queryParams" [target]="isBlank ? '_blank': '_self'" [ngClass]="cssClasses">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</a> <a *ngIf="(isSTL)" class="stl-button" (click)="open(stlcontent)"><i class="fas fa-eye"></i></a>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #stlcontent let-modal>
  <div class="modal-header" >
    <h4 class="modal-title" id="modal-basic-title">STL Preview: {{filename}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ds-stl-viewer
      [href]="this.bitstream._links.content.href">
    </ds-stl-viewer>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
