<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div id="avs3-video-wrapper" class="mb-4">
  <video #mediaPlayer id="avs3-video-player"
         class="video-js vjs-big-play-centered vjs-fluid {{customClass}}" controls preload="none">
    <p class="vjs-no-js">
      To view this video please enable JavaScript, and consider upgrading to a web browser that
      <a href="https://videojs.com/html5-video-support/"
         target="_blank"> supports HTML5 video </a>
    </p>
  </video>
  <ng-container *ngIf="isPlaylist">
    <div id="avs3-playlist-container" class="avs3-video-player-dimensions vjs-fluid">
      <ol id="avs3-playlist" class="vjs-playlist"></ol>
    </div>
  </ng-container>
</div>
