/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { AtmireMetadataFieldWrapperComponent } from './item-page/field-components/metadata-field-wrapper/atmire-metadata-field-wrapper.component';
import { AtmireMetadataUriValuesComponent } from './item-page/field-components/metadata-uri-values/atmire-metadata-uri-values.component';
import { AtmireMetadataValuesComponent } from './item-page/field-components/metadata-values/atmire-metadata-values.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../app/shared/shared.module';

const DECLARATIONS = [
  AtmireMetadataFieldWrapperComponent,
  AtmireMetadataUriValuesComponent,
  AtmireMetadataValuesComponent,
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS,
  ],
})
export class ImportSharedModule {

}
