/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IMPORT_MODULE_PATH } from './atmire-app-routing-paths';
import { EndUserAgreementCurrentUserGuard } from '../app/core/end-user-agreement/end-user-agreement-current-user.guard';
import { MenuResolver } from '../app/menu.resolver';
import { AuthBlockingGuard } from '../app/core/auth/auth-blocking.guard';
import { ServerCheckGuard } from '../app/core/server-check/server-check.guard';
import { SAVED_ITEM_LISTS_MODULE_PATH } from './atmire-app-routing-paths';
import { ATMIRE_CUA_MODULE_PATH } from './atmire-app-routing-paths';
import { AtmireSavedItemListAuthenticatedGuard } from './atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-authenticated.guard';
import { SiteAdministratorGuard } from '../app/core/data/feature-authorization/feature-authorization-guard/site-administrator.guard';
import { AuthenticatedGuard } from '../app/core/auth/authenticated.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthBlockingGuard],
    canActivateChild: [ServerCheckGuard],
    resolve: [MenuResolver],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      // add custom routes here
      {
        path: SAVED_ITEM_LISTS_MODULE_PATH,
        loadChildren: () => import('./atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-page.module').then((m) => m.AtmireSavedItemListPageModule),
        canActivate: [AtmireSavedItemListAuthenticatedGuard],
      },
      {
        path: ATMIRE_CUA_MODULE_PATH,
        loadChildren: () => import('./atmire-cua/atmire-cua.module')
          .then((m) => m.AtmireCuaModule),
        canActivate: [EndUserAgreementCurrentUserGuard]
      },
      {
        path: 'exports',
        loadChildren: () => import('./atmire-selective-export/atmire-selective-export-page/atmire-selective-export-page.module').then((m) => m.AtmireSelectiveExportPageModule)
      },
      {
        path: IMPORT_MODULE_PATH,
        loadChildren: () => import('./import/import.module')
          .then((m) => m.ImportModule),
        canActivate: [AuthenticatedGuard, EndUserAgreementCurrentUserGuard]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AtmireAppRoutingModule { }
