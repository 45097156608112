/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../../app/core/data/remote-data';
import { Bitstream } from '../../../app/core/shared/bitstream.model';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { AvsConversion } from './avs-conversion.model';
import { hasValue } from '../../../app/shared/empty.util';
import { EMPTY } from 'rxjs';
import { AvsConversionService } from './avs-conversion.service';
import {flattenNestedRemoteData} from '../../core/shared/operators';

@Component({
  selector: 'avs-conversion',
  styleUrls: ['./avs-conversion.component.scss'],
  templateUrl: './avs-conversion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Avs conversion status component for editing a bitstream
 */
export class AvsConversionComponent implements OnInit {
  /**
   * The bitstream's remote data observable
   * Tracks changes and updates the view
   */
  bitstreamRD$: Observable<RemoteData<Bitstream>>;
  statusRD$: Observable<RemoteData<AvsConversion>>;

  constructor(
    private route: ActivatedRoute,
    private avsConversionService: AvsConversionService,
  ) {
  }

  /**
   * Setup the bitstream and its conversion status based on the routing
   */
  ngOnInit(): void {
    /*this.bitstreamRD$ = this.route.data.pipe(map((data) => data.bitstream));
    this.statusRD$ = this.bitstreamRD$.pipe(
      flattenNestedRemoteData((bitstream) => {
        if (hasValue(bitstream.atmireAvsConversion)) {
          return bitstream.atmireAvsConversion;
        } else {
          return EMPTY;
        }
      }),
    );*/
  }

  /**
   * Undo a conversion
   */
  unconvert(object: AvsConversion) {
    this.avsConversionService.unconvert(object);
  }

  /**
   * Rerun the conversion
   */
  reconvert(object: AvsConversion) {
    this.avsConversionService.reconvert(object);
  }

}
