/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../../../app/core/shared/context.model';
import { PublicationComponent as BaseComponent } from '../../../../../../../app/item-page/simple/item-types/publication/publication.component';

/**
 * Component that represents a publication Item page
 *
 * todo: this component is a placeholder to ensure client themes use the same template for publications and untyped Items.
 *       when implementing a new client theme:
 *          - copy the publication template & styles from the atmire theme
 *          - point `styleUrls` and `templateUrl` to these files
 *          - uncomment the decorator
 */
// @listableObjectComponent('Publication', ViewMode.StandalonePage, Context.Any, 'client')
@Component({
  selector: 'ds-publication',
  styleUrls: [
    '../../../../../../../app/item-page/simple/item-types/publication/publication.component.scss',
    // './publication.component.scss'
  ],
  templateUrl: '../../../../../../../app/item-page/simple/item-types/publication/publication.component.html',
  // templateUrl: './publication.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicationComponent extends BaseComponent {

}
