<div class="item" [id]="dso.id">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'"
     rel="noopener noreferrer"
     [routerLink]="[itemPageRoute]" class="lead">
  </a>
  <div class="data">
    <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" [attr.rel]="(linkType == linkTypes.ExternalLink) ? 'noopener noreferrer' : null"
       [routerLink]="[itemPageRoute]" class="lead"
       [innerHTML]="dsoTitle"></a>
    <span *ngIf="linkType == linkTypes.None" class="lead" [innerHTML]="dsoTitle"></span>
    <ds-truncatable [id]="dso.id" class="text-muted">
      <ds-truncatable-part [id]="dso.id" [minLines]="1" class="author">
        <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
        <span *ngIf="dso.allMetadata(['person.jobTitle']).length > 0" class="item-list-job-title">
          <span *ngFor="let value of allMetadataValues(['person.jobTitle']); let last=last;">
              <span [innerHTML]="value"><span [innerHTML]="value"></span></span>
          </span>
        </span>
      </ds-truncatable-part>
    </ds-truncatable>
  </div>
</div>
