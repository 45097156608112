<footer class="top-footer">
    <div class="container row iaFooter">
        <div class="col-sm-12 col-md-3 ">
          <div class="footerBrandImage">
            <img src="//cdn.theme.iastate.edu/img/isu-stacked.svg" class="wordmark-isu" alt="Iowa State University">
            <a class="footerUniLink" href="//www.lib.iastate.edu">University Library</a>
            <a class="footerUniLink" href="https://instr.iastate.libguides.com/digitalscholarship">Digital Scholarship LibGuides</a>
            <a class="footerUniLink" href="http://digital.lib.iastate.edu/">Digital Scholarship &amp; Initiatives</a>
            <a class="footerUniLink" href="https://digital.lib.iastate.edu/research-projects">Research Projects</a>
          </div>
        </div>
        <div class="col-sm-12 col-md-3 ">
          <div class="uniInfo">
            <p>
              <strong>University Library Digital Initiative</strong><br><a href="https://goo.gl/maps/NFhVidKCWDq">701 Morrill Road<br>
              204 Parks Library<br>
              Iowa State University<br>
              Ames, IA 50011</a>
            </p>
          </div>
        </div>
        <div class="col-sm-12 col-md-3">
          <div class="socialLinks">
            <a class="footerDSI" href="https://digital.lib.iastate.edu/blog"><svg class="svg-inline--fa fa-wordpress fa-w-16 footer-social-icon" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="wordpress" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M61.7 169.4l101.5 278C92.2 413 43.3 340.2 43.3 256c0-30.9 6.6-60.1 18.4-86.6zm337.9 75.9c0-26.3-9.4-44.5-17.5-58.7-10.8-17.5-20.9-32.4-20.9-49.9 0-19.6 14.8-37.8 35.7-37.8.9 0 1.8.1 2.8.2-37.9-34.7-88.3-55.9-143.7-55.9-74.3 0-139.7 38.1-177.8 95.9 5 .2 9.7.3 13.7.3 22.2 0 56.7-2.7 56.7-2.7 11.5-.7 12.8 16.2 1.4 17.5 0 0-11.5 1.3-24.3 2l77.5 230.4L249.8 247l-33.1-90.8c-11.5-.7-22.3-2-22.3-2-11.5-.7-10.1-18.2 1.3-17.5 0 0 35.1 2.7 56 2.7 22.2 0 56.7-2.7 56.7-2.7 11.5-.7 12.8 16.2 1.4 17.5 0 0-11.5 1.3-24.3 2l76.9 228.7 21.2-70.9c9-29.4 16-50.5 16-68.7zm-139.9 29.3l-63.8 185.5c19.1 5.6 39.2 8.7 60.1 8.7 24.8 0 48.5-4.3 70.6-12.1-.6-.9-1.1-1.9-1.5-2.9l-65.4-179.2zm183-120.7c.9 6.8 1.4 14 1.4 21.9 0 21.6-4 45.8-16.2 76.2l-65 187.9C426.2 403 468.7 334.5 468.7 256c0-37-9.4-71.8-26-102.1zM504 256c0 136.8-111.3 248-248 248C119.2 504 8 392.7 8 256 8 119.2 119.2 8 256 8c136.7 0 248 111.2 248 248zm-11.4 0c0-130.5-106.2-236.6-236.6-236.6C125.5 19.4 19.4 125.5 19.4 256S125.6 492.6 256 492.6c130.5 0 236.6-106.1 236.6-236.6z"></path></svg><!-- <span class="footer-social-icon fab fa-wordpress" aria-hidden="true"></span> -->DSI Update</a>
          </div>
        </div>
        <div class="col-sm-12 col-md-3 ">
          <div class="footerLegal">
            <p>Copyright © 1995-2021<br>
              Iowa State University of Science and Technology<br>
              All rights reserved</p>
              <a href="http://www.policy.iastate.edu/policy/discrimination">Non-discrimination Policy</a>
              <br/>
              <a href="http://www.policy.iastate.edu/electronicprivacy">Privacy Policy</a>
              <br/>
              <a href="http://digitalaccess.iastate.edu">Digital Access &amp; Accessibility</a>
          </div>
        </div>
      </div>
</footer>
