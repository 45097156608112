<ds-metadata-field-wrapper [label]="label | translate" [spacer]="spacer">
  <div *ngIf="doiValue; else notDOI">
    <div *ngFor="let mdValue of mdValues; let last=last;">
        <div *ngIf="!mdValue.value.includes('http'); else valid;">
          <a class="dont-break-out" [href]="'http://doi.org/' + mdValue.value">
            {{ linktext || 'http://doi.org/' + mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
          </a>
        </div>
      <ng-template #valid>
        <a class="dont-break-out" [href]="mdValue.value">
          {{ linktext || mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
        </a>
      </ng-template>
    </div>
  </div>
  <ng-template #notDOI>
    <a class="dont-break-out" *ngFor="let mdValue of mdValues; let last=last;" [href]="mdValue.value">
      {{ linktext || mdValue.value }}<span *ngIf="!last" [innerHTML]="separator"></span>
    </a>
  </ng-template>
</ds-metadata-field-wrapper>
