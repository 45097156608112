/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AbstractListableElementComponent } from '../../../../app/shared/object-collection/shared/object-collection-element/abstract-listable-element.component';
import { Component, OnInit } from '@angular/core';
import { isNotEmpty } from '../../../../app/shared/empty.util';
import { DSpaceObject } from '../../../../app/core/shared/dspace-object.model';
import { ImportEntryListElementMetadataConfig } from './import-entry-list-element-metadata-config.model';

@Component({
  selector: 'ds-abstract-metadata-import-entry-list-element',
  template: ``,
})
/**
 * Abstract component representing a list element for a metadata import entry
 * It displays a header defined by the value returned by abstract method getDisplayName()
 * It displays some standard metadata below the header (like authors, identifiers...)
 * It displays a collapsable section containing extra metadata defined by abstract method getExtraMetadata()
 */
export class AbstractMetadataImportEntryListElementComponent<T extends DSpaceObject> extends AbstractListableElementComponent<T> implements OnInit {
  /**
   * A list of extra metadata to display
   * Wrapped in an object containing information about how to display the relevant metadata values
   */
  extraMetadata: ImportEntryListElementMetadataConfig[];

  /**
   * Whether or not there's at least one metadata value found for the list of extra metadata
   * Used to hide/show the collapse button
   */
  hasExtraMetadata: boolean;

  /**
   * Whether or not the extra metadata field section is collapsed
   */
  metadataCollapsed = false;

  ngOnInit(): void {
    this.extraMetadata = this.getExtraMetadata();
    this.hasExtraMetadata = isNotEmpty(this.extraMetadata.filter((metadata) => this.object.hasMetadata(metadata.keys)));
  }

  /**
   * Get the list of extra metadata values to display in the collapsable area
   */
  getExtraMetadata(): ImportEntryListElementMetadataConfig[] {
    return [];
  }

  /**
   * Get the display name of the entry
   */
  getDisplayName(): string {
    return this.object.hasMetadata('dc.title') ? this.object.firstMetadata('dc.title').value : undefined;
  }
}
