<div class="container">
    <ng-container *ngVar="(parent$ | async) as parent">
    <ng-container *ngIf="parent?.payload as parentContext">
      <div class="d-flex flex-row border-bottom mb-4 pb-4">
        <header class="comcol-header mr-auto">
          <!-- Parent Name -->
          <ds-comcol-page-header [name]="parentContext.name">
          </ds-comcol-page-header>
          <!-- Handle -->
          <ds-comcol-page-handle
            [content]="parentContext.handle"
            [title]="parentContext.type+'.page.handle'" >
          </ds-comcol-page-handle>
          <!-- Introductory text -->
          <ds-comcol-page-content [content]="parentContext.introductoryText" [hasInnerHtml]="true">
          </ds-comcol-page-content>
          <!-- News -->
          <ds-comcol-page-content [content]="parentContext.sidebarText" [hasInnerHtml]="true" [title]="'community.page.news'">
          </ds-comcol-page-content>
        </header>
        <div>
          <span *ngIf="parentContext.type == 'community'">{{'community.simple-search' | translate}}</span>
          <span *ngIf="parentContext.type == 'collection'">{{'collection.simple-search' | translate}}</span>
          <ds-search-form id="search-form" [scope]="parentContext.uuid.scope">
          </ds-search-form>
        </div>
        <div class="pl-2 d-flex" *ngVar="(parentVariables$ | async) as parentVars">
          <ds-atmire-selective-export-dropdown class="mr-1" [searchConfig]="exportSearchOptions$ | async" [msgPrefix]="parentVars?.type" [compact]="true"></ds-atmire-selective-export-dropdown>
          <ds-dso-page-edit-button *ngIf="parentVars?.isEditAuthorized" [pageRoute]="parentVars?.pageRoute" [dso]="parentVars?.type" [tooltipMsg]="parentVars?.editTooltipMsg"></ds-dso-page-edit-button>
        </div>
      </div>
      <!-- Browse-By Links -->
      <ds-themed-comcol-page-browse-by [id]="parentContext.id" [contentType]="parentContext.type"></ds-themed-comcol-page-browse-by>
      </ng-container></ng-container>

  <section class="comcol-page-browse-section">
    <div class="browse-by-metadata w-100">
      <ds-browse-by *ngIf="startsWithOptions" class="col-xs-12 w-100"
        title="{{'browse.title' | translate:{collection: (parent$ | async)?.payload?.name || '', field: 'browse.metadata.' + browseId | translate, value: (value)? '&quot;' + value + '&quot;': ''} }}"
        parentname="{{(parent$ | async)?.payload?.name || ''}}"
        [objects$]="(items$ !== undefined)? items$ : browseEntries$"
        [paginationConfig]="(currentPagination$ |async)"
        [sortConfig]="(currentSort$ |async)"
        [type]="startsWithType"
        [startsWithOptions]="startsWithOptions"
        [enableArrows]="true"
        (prev)="goPrev()"
        (next)="goNext()">
      </ds-browse-by>
      <ds-themed-loading *ngIf="!startsWithOptions" message="{{'loading.browse-by-page' | translate}}"></ds-themed-loading>
    </div>
  </section>
  <ng-container *ngVar="(parent$ | async) as parent">
    <ng-container *ngIf="parent?.payload as parentContext">
      <footer *ngIf="parentContext.copyrightText" class="border-top my-5 pt-4">
        <div>

          <!-- Copyright -->
          <ds-comcol-page-content [content]="parentContext.copyrightText" [hasInnerHtml]="true">
          </ds-comcol-page-content>
        </div>
      </footer>
    </ng-container>
  </ng-container>
</div>
