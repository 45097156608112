/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { WorkspaceitemDataService } from '../../../app/core/submission/workspaceitem-data.service';
import { Injectable } from '@angular/core';
import { DSOChangeAnalyzer } from '../../../app/core/data/dso-change-analyzer.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { WorkspaceItem } from '../../../app/core/submission/models/workspaceitem.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { find, map, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CoreState } from '../../../app/core/core.reducers';
import { Store } from '@ngrx/store';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { RequestService } from '../../../app/core/data/request.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { hasValue } from '../../../app/shared/empty.util';
import { MultipartPostRequest, PatchRequest, PostRequest, RestRequest } from '../../../app/core/data/request.models';
import { Operation } from 'fast-json-patch';
import { HttpOptions } from '../../../app/core/dspace-rest/dspace-rest.service';
import { sendRequest } from '../../../app/core/shared/operators';

@Injectable()
export class AtmireWorkspaceitemDataService extends WorkspaceitemDataService {
  constructor(
    protected comparator: DSOChangeAnalyzer<WorkspaceItem>,
    protected halService: HALEndpointService,
    protected http: HttpClient,
    protected notificationsService: NotificationsService,
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected store: Store<CoreState>,
    protected translate: TranslateService) {
      super(comparator, halService, http, notificationsService, requestService, rdbService, objectCache, store, translate);
    }


  /**
   * Creates a new workspace item for a parent collection
   * @param parentCollection
   */
  createNewWorkSpaceItem(parentCollection: string): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const request = new PostRequest(requestId, href);
          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId)),
    );
  }

  /**
   * Creates a new workspace item with a file for a parent collection
   * @param parentCollection
   * @param file
   */
  createNewWorkSpaceItemWithFile(parentCollection: string, file): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      tap((href: string) => {
          const formData = new FormData();
          formData.append('file', file);
          const request = new MultipartPostRequest(requestId, href, formData);

          this.requestService.send(request);
        }
      ),
      switchMap(() => this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId)),
    );

  }

  /**
   * Creates a new workspace item from an imported entry for a parent collection
   * The imported entry is supplied via its uri (selflink)
   * @param parentCollection  The ID of the collection to create the new workspace item in
   * @param entryUri          The uri or selflink of the imported entry
   */
  createNewWorkSpaceItemFromImportEntry(parentCollection: string, entryUri: string): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();
    const hrefObs = this.getBrowseEndpoint().pipe(map((href) => `${href}?owningCollection=${parentCollection}`));

    return hrefObs.pipe(
      find((href: string) => hasValue(href)),
      map((href: string) => {
          const options: HttpOptions = Object.create({});
          let headers = new HttpHeaders();
          headers = headers.append('Content-Type', 'text/uri-list');
          options.headers = headers;
          return new PostRequest(requestId, href, entryUri, options);
        }
      ),
      sendRequest(this.requestService),
      switchMap((request: RestRequest) => this.rdbService.buildFromRequestUUID<WorkspaceItem>(request.uuid)),
    );
  }

  /**
   * Overwritten method from {@link DataService} to create the endpoint using {@link WorkspaceItem}'s ID instead of UUID
   *
   * Send a patch request for a specified object
   * @param {WorkspaceItem} dso The object to send a patch request for
   * @param {Operation[]} operations The patch operations to be performed
   */
  patch(dso: WorkspaceItem, operations: Operation[]): Observable<RemoteData<WorkspaceItem>> {
    const requestId = this.requestService.generateRequestId();

    const hrefObs = this.halService.getEndpoint(this.linkPath).pipe(
      map((endpoint: string) => this.getIDHref(endpoint, dso.id)));

    hrefObs.pipe(
      find((href: string) => hasValue(href)),
      map((href: string) => {
        const request = new PatchRequest(requestId, href, operations);
        this.requestService.send(request);
      })
    ).subscribe();

    return this.rdbService.buildFromRequestUUID<WorkspaceItem>(requestId);
  }

}
