<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'orgunit.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"
                    [defaultImage]="'assets/images/orgunit-placeholder.svg'"
                    [alt]="'thumbnail.orgunit.alt'"
                    [placeholder]="'thumbnail.orgunit.placeholder'"
      >
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['organization.foundingDate']"
      [label]="'orgunit.page.dateestablished'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['organization.address.addressLocality']"
      [label]="'orgunit.page.city'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['organization.adress.addressCountry']"
      [label]="'orgunit.page.country'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.identifier']"
      [label]="'orgunit.page.id'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-themed-generic-item-page-field [item]="object"
      [fields]="['dc.description']"
      [label]="'orgunit.page.description'"
      [renderHTML]="true"
    >
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.doi']"
      [label]="'item.page.doi'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.identifier.uri']"
      [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field
      [item]="object"
      [fields]="['dc.source.uri']"
      [label]="'item.source.page.uri'">
    </ds-item-page-uri-field>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[
                                        {
                                          label: 'isOrgUnitOfPublication',
                                          filter: 'isOrgUnitOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        }
                                        ]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<div class="col-sm-12">
  <div class="btn-group" ngbDropdown>
    <button id="button-basic" ngbDropdownToggle type="button"
            class="btn btn-outline-primary float-left mr-2 dropdown-toggle"
            aria-controls="dropdown-basic"
            (click)="$event.preventDefault()"
    >
      {{"statlet.show" | translate}} <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" ngbDropdownMenu class="dropdown-menu"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem">
        <a class="dropdown-item"
           [routerLink]="'/atmire-cua/top/items'"
           [queryParams]="{isOrgUnitOfPublication:object.id}">
          {{"atmire.cua.top.header.items"|translate}}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item"
           [routerLink]="'/atmire-cua/top/countries'"
           [queryParams]="{isOrgUnitOfPublication:object.id}">
          {{"atmire.cua.top.header.countries"|translate}}
        </a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item"
           [routerLink]="'/atmire-cua/top/authors'"
           [queryParams]="{isOrgUnitOfPublication:object.id}">
          {{"atmire.cua.top.header.authors"|translate}}
        </a>
      </li>
    </ul>
  </div>
</div>
