import { Inject, Injectable } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { ConfigurationDataService } from '../core/data/configuration-data.service';
import { getFirstCompletedRemoteData } from '../core/shared/operators';
import { isEmpty } from '../shared/empty.util';
import { DOCUMENT } from '@angular/common';

/**
 * Set up Google Analytics on the client side.
 * See: {@link addTrackingIdToPage}.
 */
@Injectable()
export class GoogleAnalyticsService {

  constructor(
    private angulartics: Angulartics2GoogleAnalytics,
    private configService: ConfigurationDataService,
    @Inject(DOCUMENT) private document: any,
  ) { }

  /**
   * Call this method once when Angular initializes on the client side.
   * It requests a Google Analytics tracking id from the rest backend
   * (property: google.analytics.key), adds the tracking snippet to the
   * page and starts tracking.
   */
  addTrackingIdToPage(): void {
    this.configService.findByPropertyName('google.analytics.key').pipe(
      getFirstCompletedRemoteData(),
    ).subscribe((remoteData) => {
      // make sure we got a success response from the backend
      if (!remoteData.hasSucceeded) { return; }

      const trackingId = remoteData.payload.values[0];

      // make sure we received a tracking id
      if (isEmpty(trackingId)) { return; }

      // add trackingId snippet to page
      const keyScript = this.document.createElement('script');
      keyScript.innerHTML =   '<!-- Google tag (gtag.js) -->' +
                              `<script async src="https://www.googletagmanager.com/gtag/js?id=${trackingId}"></script>` +
                              '<script>' +
                              'window.dataLayer = window.dataLayer || [];' +
                              'function gtag(){dataLayer.push(arguments);}' +
                              `gtag('js', new Date());` +
                              `gtag('config', ${trackingId});` +
                              `</script>`;
      this.document.body.appendChild(keyScript);

      // start tracking
      this.angulartics.startTracking();
    });
  }
}
