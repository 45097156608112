import { Component } from '@angular/core';
import { ViewMode } from '../../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { ItemSearchResultListElementComponent } from '../../../../../shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { Item } from '../../../../../core/shared/item.model';
import {BitstreamDataService} from '../../../../../core/data/bitstream-data.service';
import {TruncatableService} from '../../../../../shared/truncatable/truncatable.service';
import {Observable} from 'rxjs/internal/Observable';
import {Bitstream} from '../../../../../core/shared/bitstream.model';
import {getFirstSucceededRemoteDataPayload} from '../../../../../core/shared/operators';
import {DSONameService} from '../../../../../core/breadcrumbs/dso-name.service';

@listableObjectComponent('OrgUnitSearchResult', ViewMode.ListElement)
@listableObjectComponent('OrgUnit', ViewMode.ListElement)
@Component({
  selector: 'ds-org-unit-search-result-list-element',
  styleUrls: ['./org-unit-search-result-list-element.component.scss'],
  templateUrl: './org-unit-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Organisation Unit
 */
export class OrgUnitSearchResultListElementComponent extends ItemSearchResultListElementComponent {

  constructor(protected bitstreamDataService: BitstreamDataService,
              protected truncatableService: TruncatableService,
              protected dsoNameService: DSONameService) {
    super(bitstreamDataService, truncatableService, dsoNameService);
  }
}
