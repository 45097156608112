/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { hasNoValue, hasValue } from '../../../app/shared/empty.util';
import { AvsConfig } from './avs-config.model';
import {Observable, Subscription} from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { AvsConfigService } from './avs-config.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import {map, skipWhile, takeWhile, tap} from 'rxjs/operators';
import {AvsHooks} from '../hooks/avs-hooks';

@Component({
  selector: 'atmire-avs-config-forms',
  styleUrls: [],
  templateUrl: './avs-config-forms.component.html',
})
/**
 * Forms to edit the AVS Settings
 */
export class AvsConfigFormsComponent implements OnInit {

  @Input() avsConfig$: Observable<RemoteData<AvsConfig>>;
  @Input() i18nPrefix: string;

  avsConfig: AvsConfig;
  updatedPlayerPermissions: string;
  updatedPlayerType: string;

  private _isSubmitting: boolean;

  @Input('submitForm')
  set submitForm(avsHooks: AvsHooks) {
    if (hasValue(avsHooks)) {
      avsHooks.subscribe((event) => {
        if (this.shouldSubmit()) {
          const request = this.submit().pipe(
            map((remoteData) => remoteData.hasCompleted)
          );
          event.add(request);
        }
      });
    }
  }

  constructor(
    private avsConfigService: AvsConfigService,
    private notificationService: NotificationsService,
    private translateService: TranslateService,
  ) {
  }

  /**
   * Setup form listeners
   */
  ngOnInit(): void {
    this.avsConfig$.subscribe((avsConfigRD) => {
      if (hasValue(avsConfigRD.payload)) {
        this.avsConfig = avsConfigRD.payload;
      }
    });
  }

  /**
   * Check if submitting is necessary, valid and not happening already.
   */
  shouldSubmit(): boolean {
    return !this._isSubmitting
      && hasValue(this.avsConfig)
      && (this.getNewPlayerPermissions() !== this.avsConfig.playerPermissions
        || this.getNewPlayerType() !== this.avsConfig.playerType
      );
  }

  /**
   * Submit the forms to update the AVS Settings
   */
  submit(): Observable<RemoteData<AvsConfig>> {
    this._isSubmitting = true;
    const newConfig = new AvsConfig();
    newConfig.playerPermissions = this.getNewPlayerPermissions();
    newConfig.playerType = this.getNewPlayerType();
    newConfig._links = this.avsConfig._links;
    const putRequest = this.avsConfigService.put(newConfig);
    putRequest.pipe(
        takeWhile((remoteData) => remoteData.isLoading, true),
      ).subscribe((remotedata) => {
        this._isSubmitting = false;
        if (remotedata.hasSucceeded) {
          this.notificationService.success(this.translateService.get(`avs.default.config.submit.success`));
        } else if (remotedata.hasFailed) {
          this.notificationService.error(this.translateService.get(`avs.default.config.submit.failed`));
        }
      });
    return putRequest;
  }

  /**
   * The value to submit for the player type
   */
  private getNewPlayerType() {
    return this.updatedPlayerType || this.avsConfig.playerType;
  }

  /**
   * Set the value to submit for the player type
   * @param value
   */
  public setNewPlayerType(value: string) {
    this.updatedPlayerType = value;
  }

  /**
   * The value to submit for the player permissions
   */
  private getNewPlayerPermissions() {
    return this.updatedPlayerPermissions || this.avsConfig.playerPermissions;
  }

  /**
   * Set the value to submit for the player permissions
   * @param value
   */
  public setNewPlayerPermissions(value: string) {
    this.updatedPlayerPermissions = value;
  }

}
