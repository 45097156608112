<ng-container *ngIf="!isLinkExternal()">
<a class="nav-item nav-link"
   [ngClass]="{ 'disabled': !hasLink }"
   [attr.aria-disabled]="!hasLink"
   [title]="item.text | translate"
   [routerLink]="getRouterLink()"
   [queryParams]="item.queryParams"
   (click)="$event.stopPropagation()"
   (keyup.space)="navigate($event)"
   (keyup.enter)="navigate($event)"
   href="javascript:void(0);">{{item.text | translate}}</a>
</ng-container>
<ng-container *ngIf="isLinkExternal()">
  <a class="nav-item nav-link"  [ngClass]="{'disabled': !hasLink}" [href]="item.link">{{item.text | translate}}</a>
</ng-container>
