<ds-metadata-field-wrapper [label]="label | translate" [spacer]="spacer" *ngIf="!renderHTML">
    <span class="dont-break-out" *ngFor="let mdValue of mdValues; let last=last;">
        {{mdValue.value}}<span *ngIf="!last" [innerHTML]="separator"></span>
    </span>
</ds-metadata-field-wrapper>
<ds-metadata-field-wrapper [label]="label | translate" *ngIf="renderHTML">
    <span *ngFor="let mdValue of mdValues; let last=last;" [innerHTML]="mdValue.value">
        <span *ngIf="!last" [innerHTML]="separator"></span>
    </span>
</ds-metadata-field-wrapper>
