/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { Injectable } from '@angular/core';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { AvsConversion } from './avs-conversion.model';
import { AVS_CONVERSION } from './avs-conversion.resource-type';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { HttpClient } from '@angular/common/http';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { RequestService } from '../../../app/core/data/request.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { RequestParam } from '../../../app/core/cache/models/request-param.model';
import { PostRequest } from '../../../app/core/data/request.models';

/* tslint:disable:max-classes-per-file */

/**
 * DataService implementation
 */
class DataServiceImpl extends DataService<AvsConversion> {
  protected linkPath: 'atmireAvsConversion';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AvsConversion>) {
    super();
  }

  /**
   * Request a full conversion bypassing limit checks
   * @param object The AvsConversion to force
   */
  forceConvert(object: AvsConversion) {
    const createdHref = this.buildHrefWithParams(
      object._links.self.href,
      [new RequestParam('bypassLimitCheck', true)]
    );
    const requestId = this.requestService.generateRequestId();
    this.requestService.send(new PostRequest(requestId, createdHref));
    return requestId;
  }

}

/**
 * Service to initiate AVS conversions or get its status
 */
@Injectable({providedIn: 'root'})
@dataService(AVS_CONVERSION)
export class AvsConversionService {
  /**
   * A private DataService instance to delegate specific methods to.
   */
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AvsConversion>,
  ) {
    this.dataService = new DataServiceImpl(requestService, rdbService, store, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Returns an observable of {@link RemoteData} of an AvsConversion, based on an href, with a list of {@link FollowLinkConfig},
   * to automatically resolve {@link HALLink}s of the AvsConversion
   * @param href                        The url of object we want to retrieve
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findByHref(href: string, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<AvsConversion>[]): Observable<RemoteData<AvsConversion>> {
    return this.dataService.findByHref(href, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

  /**
   * Request the undoing of a conversion
   * @param object The AvsConversion to undo
   */
  unconvert(object: AvsConversion) {
    this.dataService.deleteBySelfLink(object._links.self.href);
  }

  /**
   * Request the redoing of a conversion
   * @param object The AvsConversion to redo
   */
  reconvert(object: AvsConversion) {
    this.dataService.put(object);
  }

  /**
   * Request a full conversion bypassing limit checks
   * @param object The AvsConversion to force
   */
  forceConvert(object: AvsConversion) {
    this.dataService.forceConvert(object);
  }

}
