/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { WorkflowItemDataService } from '../../../app/core/submission/workflowitem-data.service';
import { Injectable } from '@angular/core';
import { WorkflowItem } from '../../../app/core/submission/models/workflowitem.model';
import { RemoteData } from '../../../app/core/data/remote-data';
import { Observable } from 'rxjs';
import { Operation } from 'fast-json-patch';
import { find, map } from 'rxjs/operators';
import { hasValue } from '../../../app/shared/empty.util';
import { PatchRequest } from '../../../app/core/data/request.models';

@Injectable()
export class AtmireWorkflowItemDataService extends WorkflowItemDataService {
  /**
   * Overwritten method from {@link DataService} to create the endpoint using {@link WorkflowItem}'s ID instead of UUID
   *
   * Send a patch request for a specified object
   * @param {WorkflowItem} dso The object to send a patch request for
   * @param {Operation[]} operations The patch operations to be performed
   */
  patch(dso: WorkflowItem, operations: Operation[]): Observable<RemoteData<WorkflowItem>> {
    const requestId = this.requestService.generateRequestId();

    const hrefObs = this.halService.getEndpoint(this.linkPath).pipe(
      map((endpoint: string) => this.getIDHref(endpoint, dso.id)));

    hrefObs.pipe(
      find((href: string) => hasValue(href)),
      map((href: string) => {
        const request = new PatchRequest(requestId, href, operations);
        this.requestService.send(request);
      })
    ).subscribe();

    return this.rdbService.buildFromRequestUUID<WorkflowItem>(requestId);
  }

}
