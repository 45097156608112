/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { MetadataSuggestionEntry } from '../shared/metadata-suggestion-entry.model';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DSOChangeAnalyzer } from '../../../app/core/data/dso-change-analyzer.service';
import { Injectable } from '@angular/core';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { METADATA_SUGGESTION_ENTRY } from '../shared/metadata-suggestion-entry.resource-type';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { FindListOptions } from '../../../app/core/data/request.models';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { MetadataSuggestion } from '../shared/metadata-suggestion.model';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';
import { map } from 'rxjs/operators';
import { SubmissionObject } from '../../../app/core/submission/models/submission-object.model';
import { hasValue } from '../../../app/shared/empty.util';

/* tslint:disable:max-classes-per-file */
class DataServiceImpl extends DataService<MetadataSuggestionEntry> {
  protected linkPath = 'metadatasuggestionentries';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<MetadataSuggestionEntry>) {
    super();
  }

  /**
   * Find the list of metadata suggestion entries by metadata suggestion, workspace item (ID) and search query
   * @param suggestion        The metadata suggestion to list entries for
   * @param submissionObject  The {@link SubmissionObject} we're listing entries for
   * @param query             The search query to use for listing entries
   * @param findListOptions   Options to use for the paginated list
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findAllByMetadataSuggestion(suggestion: MetadataSuggestion, submissionObject: SubmissionObject, query: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestionEntry>[]) {
    return this.findAllByHref(this.buildHrefFromFindOptions(suggestion._links.entries.href, findListOptions, [`${submissionObject.type}=${submissionObject.id}`, `query=${query}`], ...linksToFollow), {}, true, true, ...linksToFollow);
  }
}

/**
 * A service responsible for fetching/sending data from/to the REST API on the metadatasuggestionentries endpoint
 */
@Injectable()
@dataService(METADATA_SUGGESTION_ENTRY)
export class MetadataSuggestionEntryDataService {
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DSOChangeAnalyzer<MetadataSuggestionEntry>) {
    this.dataService = new DataServiceImpl(requestService, rdbService, null, objectCache, halService, notificationsService, http, comparator);
  }

  findByHref(href: string, ...linksToFollow: FollowLinkConfig<MetadataSuggestionEntry>[]): Observable<RemoteData<MetadataSuggestionEntry>> {
    return this.dataService.findByHref(href, true, true, ...linksToFollow);
  }

  findAllByHref(href: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestionEntry>[]): Observable<RemoteData<PaginatedList<MetadataSuggestionEntry>>> {
    return this.dataService.findAllByHref(href, findListOptions, true, true, ...linksToFollow);
  }

  /**
   * Find a single entry for a metadata suggestion, item (ID) of a type (workflow, workspace, ...) and the entry's ID
   * @param suggestion        The metadata suggestion to find an entry for
   * @param submissionObject  The {@link SubmissionObject} we're finding an entry for
   * @param entryId           The ID of the entry
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findByMetadataSuggestion(suggestion: MetadataSuggestion, submissionObject: SubmissionObject, entryId: string, ...linksToFollow: FollowLinkConfig<MetadataSuggestionEntry>[]): Observable<RemoteData<MetadataSuggestionEntry>> {
    return this.findByHref(`${suggestion._links.self.href}/entryValues/${entryId}?${submissionObject.type}=${submissionObject.id}`, ...linksToFollow);
  }

  /**
   * Find the list of entries for a metadata suggestion, item (ID) of a type (workflow, workspace, ...) and search query
   * @param suggestion        The metadata suggestion to list entries for
   * @param submissionObject  The {@link SubmissionObject} we're listing entries for
   * @param query             The search query to use for listing entries
   * @param findListOptions   Options to use for the paginated list
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findAllByMetadataSuggestion(suggestion: MetadataSuggestion, submissionObject: SubmissionObject, query: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<MetadataSuggestionEntry>[]): Observable<RemoteData<PaginatedList<MetadataSuggestionEntry>>> {
    return this.dataService.findAllByMetadataSuggestion(suggestion, submissionObject, query, findListOptions, ...linksToFollow);
  }

  /**
   * Count the total amount of entries for a metadata suggestion, item (ID) of a type (workflow, workspace, ...) and search query
   * @param suggestion        The metadata suggestion to count entries for
   * @param submissionObject  The {@link SubmissionObject} we're counting entries for
   * @param query             The search query to use for counting entries
   * @param findListOptions   Options to use for the paginated list
   */
  countEntriesForMetadataSuggestion(suggestion: MetadataSuggestion, submissionObject: SubmissionObject, query: string, findListOptions: FindListOptions = {}): Observable<number> {
    return this.findAllByMetadataSuggestion(suggestion, submissionObject, query, Object.assign({}, findListOptions, { elementsPerPage: 1 })).pipe(
      getFirstCompletedRemoteData(),
      map((rd: RemoteData<PaginatedList<MetadataSuggestionEntry>>) => {
        if (hasValue(rd.payload)) {
          return rd.payload.totalElements;
        } else {
          return Number.NaN;
        }
      })
    );
  }
}
