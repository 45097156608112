/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { link, typedObject } from '../../../app/core/cache/builders/build-decorators';
import { autoserialize, autoserializeAs, deserialize, inheritSerialization } from 'cerialize';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { BITSTREAM_METADATA_EXTRACTION_ENTRY } from './bitstream-metadata-extraction-entry.resource-type';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { BitstreamMetadataExtraction } from './bitstream-metadata-extraction.model';
import { BITSTREAM_METADATA_EXTRACTION } from './bitstream-metadata-extraction.resource-type';

/**
 * Class representing a DSpace BitstreamMetadataExtractionsEntry
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class BitstreamMetadataExtractionEntry extends DSpaceObject {
  static type = BITSTREAM_METADATA_EXTRACTION_ENTRY;

  /**
   * The identifier of this bitstream metadata extractions entry
   */
  @autoserialize
  id: string;

  /**
   * The name of this entry
   */
  @autoserializeAs('name')
  entryName: string;

  @deserialize
  _links: {
    parent: HALLink;
    self: HALLink;
  };

  /**
   * The metadata extraction this entry belongs to
   */
  @link(BITSTREAM_METADATA_EXTRACTION)
  parent?: Observable<RemoteData<BitstreamMetadataExtraction>>;
}
