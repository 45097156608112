import {Component, Input, OnInit} from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';

@Component({
  selector: 'ds-subject-category-item-page-field',
  styleUrls: ['./item-page-subject-category-field.component.scss'],
  templateUrl: './item-page-subject-category-field.component.html'
})
/**
 * This component can be used to represent metadata on a simple item page.
 * It is the most generic way of displaying metadata values
 * It expects 4 parameters: The item, a separator, the metadata keys and an i18n key
 */
export class SubjectCategoryItemPageFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  categories: {link: string, value: string}[];

  /**
   * Initialize instance variables
   */
  ngOnInit(): void {
    const fields = this.item?.allMetadata(this.fields);
    this.categories = [];
    for (const field of fields) {
      const values = field.value.split('::');
      const value = values[values.length - 1];
      const link = '/search?page=1&spc.page=1&f.subject=' + value.replace(' ', '%20') + ',equals';
      this.categories.push({link: link, value: value});

      // Client only wants most-specific (last) term to show, commenting old work out
      /*for (let value of values) {
        if (value == "DegreeDisciplines") {
          continue;
        }
        let link = "/search?page=1&spc.page=1&f.subject=" + value.replace(" ", "%20") + ",equals";
        this.categories.push({link: link, value: value});
      }*/
    }
  }
}
