<ng-container *ngFor="let objectPage of objects; let i = index">
  <ng-container *ngVar="(objectPage | async) as itemsRD">
    <div class="orgunits">
      <ng-container *ngFor="let item of itemsRD?.payload?.page | slice:0:10; let i=index">
        <h4 *ngIf="i<1">Orgunits</h4>
        <ng-container *ngVar="item.firstMetadata('dc.title') as title">

          <a [routerLink]="'/items/'+item.uuid">{{this.getTitle(item)}}</a>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-container>


