/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { MetadataUriValuesComponent } from '../../../../../../app/item-page/field-components/metadata-uri-values/metadata-uri-values.component';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ds-atmire-metadata-uri-values',
  styleUrls: ['../../../../../../app/item-page/field-components/metadata-uri-values/metadata-uri-values.component.scss'],
  templateUrl: './atmire-metadata-uri-values.component.html'
})
export class AtmireMetadataUriValuesComponent extends MetadataUriValuesComponent {
  /**
   * Whether or not to add spacing at the bottom of the component
   */
  @Input() spacer = true;
}
