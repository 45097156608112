<div>
  <span [className]="badgeClass">
      <ng-container *ngIf="stepID$ | async as step else statusText">
        {{ 'mydspace.step.' + step | translate }}
        (<ng-container *ngTemplateOutlet="statusText"></ng-container>)
      </ng-container>
  </span>
</div>

<ng-template #statusText>{{status | translate}}</ng-template>
