/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { link, typedObject } from '../../../app/core/cache/builders/build-decorators';
import { autoserialize, deserialize } from 'cerialize';
import { BITSTREAM_METADATA_EXTRACTION } from './bitstream-metadata-extraction.resource-type';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { BitstreamMetadataExtractionEntry } from './bitstream-metadata-extraction-entry.model';
import { BITSTREAM_METADATA_EXTRACTION_ENTRY } from './bitstream-metadata-extraction-entry.resource-type';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';
import { ResourceType } from '../../../app/core/shared/resource-type';

/**
 * Class representing a DSpace BitstreamMetadataExtraction
 */
@typedObject
export class BitstreamMetadataExtraction implements CacheableObject {
  static type = BITSTREAM_METADATA_EXTRACTION;

  /**
   * The unique identifier of this extraction type
   */
  @autoserialize
  id: string;

  /**
   * The name of the extraction
   */
  @autoserialize
  name: string;

  /**
   * A string representing the kind of Object
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @deserialize
  _links: {
    entries: HALLink;
    self: HALLink;
  };

  /**
   * The list of metadata entries this extraction contains
   */
  @link(BITSTREAM_METADATA_EXTRACTION_ENTRY, true)
  entries?: Observable<RemoteData<PaginatedList<BitstreamMetadataExtractionEntry>>>;

  /**
   * A shorthand to get this BitstreamMetadataExtraction's self link
   */
  get self(): string {
    return this._links.self.href;
  }

  /**
   * A shorthand to set this BitstreamMetadataExtraction's self link
   */
  set self(v: string) {
    this._links.self = {
      href: v
    };
  }
}
