import { Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../core/shared/view-mode.model';
import { ItemSearchResult } from '../../../../../object-collection/shared/item-search-result.model';
import { SearchResultListElementComponent } from '../../../search-result-list-element.component';
import { Item } from '../../../../../../core/shared/item.model';
import { BitstreamDataService } from '../../../../../../core/data/bitstream-data.service';
import {TruncatableService} from '../../../../../truncatable/truncatable.service';
import { getItemPageRoute } from '../../../../../../item-page/item-page-routing-paths';
import {DSONameService} from '../../../../../../core/breadcrumbs/dso-name.service';

@listableObjectComponent('PublicationSearchResult', ViewMode.ListElement)
@listableObjectComponent(ItemSearchResult, ViewMode.ListElement)
@listableObjectComponent('Publication', ViewMode.ListElement)
@listableObjectComponent(Item, ViewMode.ListElement)
@Component({
  selector: 'ds-item-search-result-list-element',
  styleUrls: ['./item-search-result-list-element.component.scss'],
  templateUrl: './item-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type Publication
 */
export class ItemSearchResultListElementComponent extends SearchResultListElementComponent<ItemSearchResult | Item, Item> {
  /**
   * Route to the item's page
   */
  itemPageRoute: string;

  constructor(protected bitstreamDataService: BitstreamDataService,
              protected truncatableService: TruncatableService,
              protected dsoNameService: DSONameService) {
    super(truncatableService, dsoNameService);
  }

  isRTSFind() {
    return window.location.href.includes('rts-find');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.itemPageRoute = getItemPageRoute(this.dso);
  }
}
