/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
/**
 * A media player for a given item
 */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MediaInfo } from './media-info.model';
import videojs from 'video.js';
import 'videojs-playlist';
import './lib/videojs-playlist-ui.js';

@Component({
  selector: 'atmire-avs-player-playlist',
  styleUrls: ['./avs-player-playlist.component.scss'],
  templateUrl: './avs-player-playlist.component.html',
})
/**
 * The media player for one or multiple media files
 */
export class AvsPlayerPlaylistComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('mediaPlayer', {static: true}) mediaPlayer: ElementRef;
  @Input() media: MediaInfo;
  player;
  customClass = '';
  isPlaylist = false;

  /**
   * Setup the component for the given number of media files
   */
  ngOnInit(): void {
    this.isPlaylist = this.media.videos.length + this.media.audios.length > 1;
  }

  /**
   * Configure and run videojs
   */
  ngAfterViewInit() {

    const viewer = this.media;
    const videos = viewer.videos || [];
    const audios = viewer.audios || [];

    if (videos.length > 0) {
      const numMedia = videos.length + audios.length;

      this.player = videojs(this.mediaPlayer.nativeElement, {
        html5: {
          nativeCaptions: false,
        },
        techOrder: ['html5'],
      });

      if (numMedia === 1) {
        const video = videos[0];
        const sources = [];
        if (video.hls) {
          sources.push({
            src: video.hls,
            type: 'application/x-mpegURL',
          });
        }
        if (video.dash) {
          sources.push({
            src: video.dash,
            type: 'application/dash+xml',
          });
        }
        if (!video.dash && !video.hls && video.direct) {
          sources.push({
            src: video.direct,
            type: video.mimetype,
          });
        }
        this.player.src(sources);
        this.player.poster(video.poster);
      } else {
        this.createPlaylist(this.player, videos.concat(audios));
      }

    } else if (audios.length > 0) {
      this.customClass = audios.length === 1 ? 'atmire-avs-audio-player' : '';

      this.player = videojs(this.mediaPlayer.nativeElement, {
        html5: {
          nativeCaptions: false,
        },
        techOrder: ['html5'],
      });

      if (audios.length === 1) {
        const audio = audios[0];
        this.player.ready(() => {
          const sources = [];
          if (audio.dash) {
            sources.push({
              src: audio.dash,
              type: 'application/dash+xml',
            });
          }
          if (audio.hls) {
            sources.push({
              src: audio.hls,
              type: 'application/x-mpegURL',
            });
          }
          if (!audio.dash && !audio.hls && audio.direct) {
            sources.push({
              src: audio.direct,
              type: audio.mimetype,
            });
          }
          this.player.src(sources);
        });
      } else {
        this.createPlaylist(this.player, audios);
      }
    }
  }

  /**
   * Dispose of videojs
   */
  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

  /**
   * Compose a videojs playlist
   * @param player The videojs object
   * @param videos The media files to include
   */
  createPlaylist(player, videos) {
    player.ready(() => {
      const playlist = [];
      videos.sort((a, b) => a.order - b.order);
      videos.forEach((video) => {
        const sources = [];
        if (video.dash) {
          sources.push({
            src: video.dash,
            type: 'application/dash+xml',
          });
        }
        if (video.hls) {
          sources.push({
            src: video.hls,
            type: 'application/x-mpegURL',
          });
        }
        if (!video.dash && !video.hls && video.direct) {
          sources.push({
            src: video.direct,
            type: video.mimetype,
          });
        }
        playlist.push({
          name: video.name,
          description: video.description,
          sources: sources,
          poster: video.poster,
          thumbnail: [
            {
              srcset: video.poster,
              type: 'image/jpeg',
              media: '(min-width: 400px;)',
            },
            {
              src: video.poster,
            },
          ],
          bitstream: video.id,
          token: video.token,
        });
      });
      player.playlist(playlist);
      player.playlist.autoadvance(0);
      player.playlistUi();
      // Array.from(
      //   document.getElementsByClassName('vjs-playlist-item'),
      // ).forEach((element, index, array) => {
      //   element.onmouseover = () => {
      //     const thumbnail = element.getElementsByClassName(
      //       'vjs-playlist-thumbnail',
      //     )[0];
      //     if (!thumbnail.classList.contains('vjs-playlist-now-playing')) {
      //       element
      //         .getElementsByClassName('vjs-playlist-thumbnail')[0]
      //         .classList.add('vjs-icon-play');
      //     }
      //   };
      //   element.onmouseleave = () => {
      //     element
      //       .getElementsByClassName('vjs-playlist-thumbnail')[0]
      //       .classList.remove('vjs-icon-play');
      //   };
      //   element.click = () => {
      //     element
      //       .getElementsByClassName('vjs-playlist-thumbnail')[0]
      //       .classList.add('vjs-playlist-now-playing');
      //   };
      // });
    });
  }

}
