<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-template #content let-c="close" let-d="dismiss">
<div class="modal-header">{{'atmire-my-dspace-new-submission.submit.select-collection' | translate}}
        <button type="button" class="close" (click)="close()" aria-label="Close">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <ds-dso-selector [types]="objectTypes" (onSelect)="setCollection($event)"></ds-dso-selector>
    </div>
</ng-template>
<div class="parent mb-3 mt-1">
  <div class="upload">
    <ds-file-dropzone-no-uploader
            [dropMessageLabel]="'atmire-my-dspace-new-submission.dropMsg'"
            [dropMessageLabelReplacement]="'atmire-my-dspace-new-submission.dropMsgReplace'"
            (onFileAdded)="setFile($event)"
    ></ds-file-dropzone-no-uploader>

  </div>
    <div class="dropdown" ngbDropdown>
        <button class="btn btn-lg btn-primary ml-2 h-100" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle >
            <i class="fa fa-plus-circle" aria-hidden="true"></i> {{'atmire-my-dspace-new-submission.submit.button' | translate}}
        </button>
        <div ngbDropdownMenu class="dropdown-menu submission-dropdown" aria-labelledby="dropdownMenuButton">
            <li class="dropdown-item" (click)="startScratchSubmission(content)">{{'atmire-my-dspace-new-submission.submit.new' |translate}}</li>
            <li [class]="hasFile()? 'dropdown-item' : 'dropdown-item disabled'" (click)="submitWithFile(content)">{{'atmire-my-dspace-new-submission.submit.with-file' |translate}}</li>
            <li [class]="hasFile()? 'dropdown-item' : 'dropdown-item disabled'" (click)="importMetadataFromFile()">{{'atmire-my-dspace-new-submission.submit.import-from-file' |translate}}</li>
            <li class="dropdown-item" (click)="startLiveImport()">{{'atmire-my-dspace-new-submission.submit.live-import' |translate}}</li>
        </div>
    </div>
</div>
