/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, autoserializeAs, deserialize, inheritSerialization } from 'cerialize';
import { DSpaceObject } from '../../../app/core/shared/dspace-object.model';
import { link, typedObject } from '../../../app/core/cache/builders/build-decorators';
import { METADATA_SUGGESTION } from './metadata-suggestion.resource-type';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { Observable } from 'rxjs';
import { RemoteData } from '../../../app/core/data/remote-data';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { MetadataSuggestionEntry } from './metadata-suggestion-entry.model';
import { METADATA_SUGGESTION_ENTRY } from './metadata-suggestion-entry.resource-type';

/**
 * Class representing a DSpace MetadataSuggestion
 */
@typedObject
@inheritSerialization(DSpaceObject)
export class MetadataSuggestion extends DSpaceObject {
  static type = METADATA_SUGGESTION;

  /**
   * The identifier of this metadata suggestion
   */
  @autoserialize
  id: string;

  /**
   * The name of this metadata suggestion
   */
  @autoserializeAs('name')
  suggestionName: string;

  /**
   * Whether or not this metadata suggestion is query based
   */
  @autoserializeAs(String, 'query-based')
  queryBased: boolean;

  /**
   * Whether or not this metadata suggestion is file based
   */
  @autoserializeAs(String, 'file-based')
  fileBased: boolean;

  /**
   * Whether or not this metadata suggestion is metadata based
   */
  @autoserializeAs(String, 'metadata-based')
  metadataBased: boolean;

  @deserialize
  _links: {
    entries: HALLink;
    self: HALLink;
  };

  /**
   * The entries for this metadata suggestion
   */
  @link(METADATA_SUGGESTION_ENTRY, true)
  entries?: Observable<RemoteData<PaginatedList<MetadataSuggestionEntry>>>;
}
