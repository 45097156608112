<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'orgunit.page.titleprefix' | translate}}<ds-metadata-values
      [mdValues]="object?.allMetadata(['organization.legalName'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object"
      [tooltipMsg]="'orgunit.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <atmire-avs-player [item]="object"></atmire-avs-player>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-thumbnail [thumbnail]="object?.thumbnail | async" [defaultImage]="'assets/images/orgunit-placeholder.svg'"
        [alt]="'thumbnail.orgunit.alt'" [placeholder]="'thumbnail.orgunit.placeholder'">
      </ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-generic-item-page-field [item]="object" [fields]="['organization.foundingDate']"
      [label]="'orgunit.page.dateestablished'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['organization.address.addressLocality']"
      [label]="'orgunit.page.city'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['organization.adress.addressCountry']"
      [label]="'orgunit.page.country'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object" [fields]="['dc.identifier']" [label]="'orgunit.page.id'">
    </ds-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-generic-item-page-field [item]="object" [fields]="['dc.description']" [label]="'orgunit.page.description'"
      [renderHTML]="true">
    </ds-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
    <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.doi']" [label]="'item.page.doi'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [fields]="['dc.identifier.uri']" [label]="'item.page.uri'">
    </ds-item-page-uri-field>
    <ds-item-page-uri-field [item]="object" [fields]="['dc.source.uri']" [label]="'item.source.page.uri'">
    </ds-item-page-uri-field>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search [item]="object" [relationTypes]="[
                                        {
                                          label: 'isOrgUnitOfPublication',
                                          filter: 'isOrgUnitOfPublication',
                                          configuration: 'publication'
                                        },
                                        {
                                          label: 'isOrgUnitOfPerson',
                                          filter: 'isOrgUnitOfPerson',
                                          configuration: 'person'
                                        }
                                        ]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
