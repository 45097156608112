/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { AvsConfig } from './config/avs-config.model';
import { AvsConversion } from './status/avs-conversion.model';
import { MediaInfo } from './media-info.model';

/**
 * Models of the AVS module
 */
export const avsModels =
  [
    AvsConfig,
    AvsConversion,
    MediaInfo,
  ];
