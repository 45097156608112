import {Component, Input, OnInit} from '@angular/core';

import { Item } from '../../../../../core/shared/item.model';
import { ItemPageFieldComponent } from '../item-page-field.component';
import {of} from 'rxjs';
import {string} from '@amcharts/amcharts4/core';
import * as url from 'url';

@Component({
  selector: 'ds-facet-search-item-page-field',
  styleUrls: ['./item-page-facet-search-field.component.scss'],
  templateUrl: './item-page-facet-search-field.component.html'
})
/**
 * This component can be used to represent metadata on a simple item page.
 * It is the most generic way of displaying metadata values
 * It expects 4 parameters: The item, a separator, the metadata keys and an i18n key
 */
export class FacetSearchItemPageFieldComponent extends ItemPageFieldComponent {

  /**
   * The item to display metadata for
   */
  @Input() item: Item;

  /**
   * Separator string between multiple values of the metadata fields defined
   * @type {string}
   */
  @Input() separator: string;

  /**
   * Fields (schema.element.qualifier) used to render their values.
   */
  @Input() fields: string[];

  /**
   * Label i18n key for the rendered metadata
   */
  @Input() label: string;

  /**
   * Render value as HTML (defaults to false)
   */
  @Input() renderHTML = false;

  createLink(field: string): string {
    const base = '/search?page=1&f.keywords=' + field.split(' ').join('%20') + ',equals';

    return base + '&spc.page=1';
  }
}
