import { Component, Injector } from '@angular/core';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { MenuService } from '../shared/menu/menu.service';
import { MenuID, MenuItemType } from '../shared/menu/initial-menus-state';
import { HostWindowService } from '../shared/host-window.service';
import { BrowseService } from '../core/browse/browse.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationDataService } from '../core/data/feature-authorization/authorization-data.service';
import { LinkMenuItemModel } from '../shared/menu/menu-item/models/link.model';
import { TextMenuItemModel } from '../shared/menu/menu-item/models/text.model';
import {environment} from '../../environments/environment';
import { ThemeService } from '../shared/theme-support/theme.service';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends MenuComponent {
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  constructor(protected menuService: MenuService,
    protected injector: Injector,
              public windowService: HostWindowService,
              public browseService: BrowseService,
              public authorizationService: AuthorizationDataService,
              public route: ActivatedRoute,
              protected themeService: ThemeService
  ) {
    super(menuService, injector, authorizationService, route, themeService);
  }

  ngOnInit(): void {
    this.createIAMenu();
    super.ngOnInit();
  }

  /**
   * Initialize all menu sections and items for this menu
   */
  createMenu() {
    const menuList: any[] = [
      /* Communities & Collections tree */
      {
        id: `browse_global_communities_and_collections`,
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.browse_global_communities_and_collections`,
          link: `/community-list`
        } as LinkMenuItemModel
      },
      /* News */
      {
        id: 'browse_global',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.browse_global'
        } as TextMenuItemModel,
        index: 0
      },
    ];
    menuList.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));

  }

  createIAMenu() {
    const menuList: any[] = [
      /* News */
      {
        id: 'browse_global',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.browse_global'
        } as TextMenuItemModel,
        index: 0
      },
      /* Communities & Collections tree */
      {
        id: `browse_global_communities_and_collections`,
        parentID: 'browse_global',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.browse_global_communities_and_collections`,
          link: `/community-list`
        } as LinkMenuItemModel
      },
    ];
    const aboutUs: any[] = [
      {
        id: 'aboutUs',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.aboutUs`,
          link: `https://digital.lib.iastate.edu/about-us`
        } as LinkMenuItemModel
      }
    ];

    const blog: any[] = [
      {
        id: 'blog',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.blog`,
          link: `https://digital.lib.iastate.edu/blog`
        } as LinkMenuItemModel
      }
    ];

    const contactUs: any[] = [
      {
        id: 'contactUs',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.contactUs`,
          link: `https://digital.lib.iastate.edu/contact-us`
        } as LinkMenuItemModel
      }
    ];

    const faq: any[] = [
      {
        id: 'faq',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.faq`,
          link: `https://digital.lib.iastate.edu/faq`
        } as LinkMenuItemModel
      }
    ];

    const affiliatedLibUnits: any[] = [
      {
        id: 'affiliatedLibUnits',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.affiliatedLibUnits'
        } as TextMenuItemModel,
        index: 0
      },
      {
        id: `openScholarshipServices`,
        parentID: 'affiliatedLibUnits',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.openScholarshipServices`,
          link: `https://open.lib.iastate.edu/`
        } as LinkMenuItemModel
      },
      {
        id: `preservation`,
        parentID: 'affiliatedLibUnits',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.preservation`,
          link: `http://www.lib.iastate.edu/about-library/organization/departments/preservation`
        } as LinkMenuItemModel
      },
      {
        id: `specialCollections`,
        parentID: 'affiliatedLibUnits',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.LINK,
          text: `menu.section.specialCollections`,
          link: `http://archives.lib.iastate.edu/`
        } as LinkMenuItemModel
      },
    ];
    menuList.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
    aboutUs.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
    blog.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
    contactUs.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
    faq.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));
    affiliatedLibUnits.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
      shouldPersistOnRouteChange: true
    })));

  }

}
