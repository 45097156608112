/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CacheableObject } from '../../../app/core/cache/object-cache.reducer';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { ResourceType } from '../../../app/core/shared/resource-type';
import { autoserialize, deserialize } from 'cerialize';
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { AVS_CONFIG } from './avs-config.resource-type';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';

/**
 * AVS Settings
 */
@typedObject
export class AvsConfig implements CacheableObject {
  static type = AVS_CONFIG;

  @autoserialize
  playerPermissions: string;

  @autoserialize
  playerType: string;

  @deserialize
  _links: { self: HALLink };

  @excludeFromEquals
  @autoserialize
  type: ResourceType;
}
