/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { DataService } from '../../../app/core/data/data.service';
import { ExternalSourceEntry } from '../../../app/core/shared/external-source-entry.model';
import { Injectable } from '@angular/core';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { FindListOptions, GetRequest } from '../../../app/core/data/request.models';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { ExternalSource } from '../../../app/core/shared/external-source.model';
import { Observable } from 'rxjs/internal/Observable';
import { map, switchMap } from 'rxjs/operators';
import { RemoteData } from '../../../app/core/data/remote-data';
import { hasValue } from '../../../app/shared/empty.util';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';
import { getFirstCompletedRemoteData } from '../../../app/core/shared/operators';

/**
 * A service handling all external source entry requests
 */
@Injectable()
export class ExternalSourceEntryDataService extends DataService<ExternalSourceEntry> {
  protected linkPath = 'externalsources';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<ExternalSourceEntry>) {
    super();
  }

  /**
   * Find a single entry by theirs and their parent source's IDs
   * @param sourceId      ID of the {@link ExternalSource} this entry originates from
   * @param entryId       ID of the {@link ExternalSourceEntry}
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findBySourceAndId(sourceId: string, entryId: string, ...linksToFollow: FollowLinkConfig<ExternalSourceEntry>[]): Observable<RemoteData<ExternalSourceEntry>> {
    return this.getBrowseEndpoint().pipe(
      map((href) => `${href}/${sourceId}/entryValues/${entryId}`),
      switchMap((href) => this.findByHref(href, true, true, ...linksToFollow))
    );
  }

  /**
   * Find a list of entries for an {@link ExternalSource} and query to filter on
   * @param source          The {@link ExternalSource} to search in
   * @param query           The query to search by
   * @param findListOptions Extra options for the list to return
   * @param linksToFollow   List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  findAllByExternalSource(source: ExternalSource, query: string, findListOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<ExternalSourceEntry>[]): Observable<RemoteData<PaginatedList<ExternalSourceEntry>>> {
    return this.findAllByHref(this.buildHrefFromFindOptions(source._links.entries.href, findListOptions, [`query=${query}`], ...linksToFollow), {}, true, true, ...linksToFollow);
  }

  findAllByHref(href: string, findListOptions: FindListOptions = {}, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: FollowLinkConfig<ExternalSourceEntry>[]): Observable<RemoteData<PaginatedList<ExternalSourceEntry>>> {
    const requestHref = this.buildHrefFromFindOptions(href, findListOptions, [], ...linksToFollow);
    const request = new GetRequest(this.requestService.generateRequestId(), requestHref);
    if (hasValue(this.responseMsToLive)) {
      request.responseMsToLive = this.responseMsToLive;
    }
    this.requestService.send(request);
    return this.rdbService.buildList<ExternalSourceEntry>(requestHref, ...linksToFollow);
  }

  /**
   * Count the total amount of entries within an {@link ExternalSource} for a search query
   * @param source  The {@link ExternalSource} to search in
   * @param query   The query to search by
   * @param options Extra options for the list to return
   */
  countEntriesForExternalSource(source: ExternalSource, query: string,  options: FindListOptions = {}): Observable<number> {
    return this.findAllByExternalSource(source, query, Object.assign({}, options, { elementsPerPage: 1 })).pipe(
      getFirstCompletedRemoteData(),
      map((rd: RemoteData<PaginatedList<ExternalSourceEntry>>) => {
        if (hasValue(rd.payload)) {
          return rd.payload.totalElements;
        } else {
          return Number.NaN;
        }
      }),
    );
  }

}
