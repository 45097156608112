<div class="item-page-field" *ngIf="item?.allMetadata(fields).length > 0">
  <h5>{{label | translate}}</h5>
    <ds-metadata-field-wrapper [label]="''" [spacer]="''" *ngIf="!renderHTML">
      <a *ngFor="let mdValue of item?.allMetadata(fields); let last=last;" [href]="createLink(mdValue.value)">
        <span class="dont-break-out">
        {{mdValue.value}}<span *ngIf="!last" [innerHTML]="separator"></span>
        </span>
      </a>
    </ds-metadata-field-wrapper>
    <ds-metadata-field-wrapper [label]="''" *ngIf="renderHTML">
      <a *ngFor="let mdValue of item?.allMetadata(fields); let last=last;" [href]="createLink(mdValue.value)">
        <span [innerHTML]="mdValue.value">
          <span *ngIf="!last" [innerHTML]="separator"></span>
        </span>
      </a>
    </ds-metadata-field-wrapper>
</div>
