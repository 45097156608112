import { ThemedComponent } from '../theme-support/themed.component';
import { ObjectListComponent } from './object-list.component';
import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, Output } from '@angular/core';
import { PaginationComponentOptions } from '../pagination/pagination-component-options.model';
import { SortDirection, SortOptions } from '../../core/cache/models/sort-options.model';
import { CollectionElementLinkType } from '../object-collection/collection-element-link.type';
import { Context } from '../../core/shared/context.model';
import { RemoteData } from '../../core/data/remote-data';
import { PaginatedList } from '../../core/data/paginated-list.model';
import { ListableObject } from '../object-collection/shared/listable-object.model';
import { ThemeService } from '../theme-support/theme.service';

/**
 * Themed wrapper for ObjectListComponent
 */
@Component({
  selector: 'ds-themed-object-list',
  styleUrls: [],
  templateUrl: '../theme-support/themed.component.html',
})
export class ThemedObjectListComponent extends ThemedComponent<ObjectListComponent> {
  protected publicProperties: (keyof ObjectListComponent & keyof this)[] = ['config', 'sortConfig', 'hasBorder',
    'hideGear', 'hidePagerWhenSinglePage', 'selectable', 'selectionConfig', 'linkType', 'context',
    'hidePaginationDetail', 'importable', 'importConfig', 'contentChange', 'objects', 'change', 'pageChange',
    'pageSizeChange', 'sortDirectionChange', 'paginationChange', 'deselectObject', 'selectObject', 'importObject',
    'sortFieldChange'];
  protected privateProperties: any[] = ['_objects'];

  protected inAndOutputNames: (keyof ObjectListComponent & keyof this)[] = [...this.publicProperties, ...this.privateProperties];

  @Input() config: PaginationComponentOptions;

  @Input() sortConfig: SortOptions;

  @Input() hasBorder = false;

  @Input() hideGear = false;

  @Input() hidePagerWhenSinglePage = true;

  @Input() selectable = false;

  @Input() selectionConfig: { repeatable: boolean, listId: string };

  @Input() linkType: CollectionElementLinkType;

  @Input() context: Context;

  @Input() hidePaginationDetail = false;

  @Input() importable = false;

  @Input() importConfig: { importLabel: string };

  @Output() contentChange = new EventEmitter<any>();

  private _objects: RemoteData<PaginatedList<ListableObject>>;

  @Input() set objects(objects: RemoteData<PaginatedList<ListableObject>>) {
    this._objects = objects;
  }

  @Output() change: EventEmitter<{
    pagination: PaginationComponentOptions,
    sort: SortOptions
  }> = new EventEmitter<{
    pagination: PaginationComponentOptions,
    sort: SortOptions
  }>();

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  @Output() sortDirectionChange: EventEmitter<SortDirection> = new EventEmitter<SortDirection>();

  @Output() paginationChange: EventEmitter<any> = new EventEmitter<any>();

  @Output() deselectObject: EventEmitter<ListableObject> = new EventEmitter<ListableObject>();

  @Output() selectObject: EventEmitter<ListableObject> = new EventEmitter<ListableObject>();

  @Output() importObject: EventEmitter<ListableObject> = new EventEmitter<ListableObject>();

  @Output() sortFieldChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected themeService: ThemeService
  ) {
    super(resolver, cdr, themeService);
  }

  protected getComponentName(): string {
    return 'ObjectListComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/object-list/object-list.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./object-list.component');
  }

}
