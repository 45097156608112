/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

export class AvsHooks {
  private events = new BehaviorSubject(new Event());

  trigger(): void {
    this.events.next(new Event());
  }

  subscribe(subscriber) {
    this.events.subscribe(subscriber);
  }

  isReady(): Observable<boolean> {
    return this.events.pipe(
      switchMap((event) => event.isReady())
    );
  }
}

// tslint:disable-next-line:max-classes-per-file
class Event {
  private hooks: Observable<boolean>[] = [];

  add(hook: Observable<boolean>) {
    this.hooks.push(hook);
  }

  isReady(): Observable<boolean> {
    return combineLatest(of(true), ...this.hooks).pipe(
      map((readies) => readies.reduce((ready, next) => ready && next, true))
    );
  }
}
