import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { StatletComponent } from './statlet.component';
import { SingleStatletComponent } from './single-statlet.component';
import { StatletService } from './statlet.service';
import { TableStatletComponent } from './table-statlet.component';
import { DonutStatletComponent } from './donut-statlet.component';
import { LineChartStatletComponent } from './line-chart-statlet.component';
import { NgxChartsModule } from 'ngx-charts-10';
import { CuaPieChartComponent } from './cua-pie-chart.component';
import { AmchartsModule } from '../amcharts/amcharts.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    CoreModule.forRoot(),
    NgxChartsModule,
    AmchartsModule,
  ],
  declarations: [
    StatletComponent,
    SingleStatletComponent,
    TableStatletComponent,
    DonutStatletComponent,
    LineChartStatletComponent,
    CuaPieChartComponent,
  ],
  providers: [
    StatletService,
  ],
  entryComponents: [],
  exports: [
    StatletComponent,
    SingleStatletComponent,
    TableStatletComponent,
    DonutStatletComponent,
    LineChartStatletComponent,
    CuaPieChartComponent,
  ],
})

/**
 * This module handles the statlets
 */
export class StatletModule {
}
