/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { ExternalSourceService } from '../../../app/core/data/external-source.service';
import { Injectable } from '@angular/core';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { ExternalSource } from '../../../app/core/shared/external-source.model';
import { ExternalSourceEntryDataService } from './external-source-entry-data.service';
import { FindListOptions } from '../../../app/core/data/request.models';
import { FollowLinkConfig } from '../../../app/shared/utils/follow-link-config.model';
import { combineLatest, Observable } from 'rxjs';
import { LiveImportSearchResult } from '../shared/live-import-search-result.model';
import { getFirstSucceededRemoteDataPayload } from '../../../app/core/shared/operators';
import { map, switchMap } from 'rxjs/operators';
import { PaginatedList } from '../../../app/core/data/paginated-list.model';

@Injectable()
export class AtmireExternalSourceService extends ExternalSourceService {
  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<ExternalSource>,
    protected externalSourceEntryService: ExternalSourceEntryDataService) {
    super(requestService, rdbService, store, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Search for {@link ExternalSource}s and return the result as an array of {@link LiveImportSearchResult}s containing
   * the amount of total entries for each source and the provided query
   * @param query         Query to search by
   * @param options       Options for the list of {@link ExternalSource}s
   * @param countOptions  Options for counting the {@link ExternalSourceEntry}s for each {@link ExternalSource}
   * @param linksToFollow List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  searchByQuery(query: string, options: FindListOptions = {}, countOptions: FindListOptions = {}, ...linksToFollow: FollowLinkConfig<ExternalSource>[]): Observable<LiveImportSearchResult<ExternalSource>[]> {
    return this.findAll(options, true, true, ...linksToFollow).pipe(
      getFirstSucceededRemoteDataPayload(),
      switchMap((sources: PaginatedList<ExternalSource>) => {
        return combineLatest(sources.page.map((source: ExternalSource) => {
          return this.externalSourceEntryService.countEntriesForExternalSource(source, query, countOptions).pipe(
            map((count: number) => new LiveImportSearchResult(source, query, count))
          );
        }));
      })
    );
  }
}
