/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { SeriesComponent as BaseComponent } from '../../../../../../../app/entity-groups/research-entities/item-pages/series/series.component';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { listableObjectComponent } from '../../../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../../../app/core/shared/view-mode.model';

@Component({
  selector: 'ds-series',
  styleUrls: ['../../../../../../../app/entity-groups/research-entities/item-pages/series/series.component.scss'],
  templateUrl: './series.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@listableObjectComponent('Series', ViewMode.StandalonePage, undefined, 'atmire')
export class SeriesComponent extends BaseComponent {

}
