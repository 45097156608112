<div class="d-flex">
  <div class="flex-grow-1">
    <h2 *ngIf="!disableHeader">{{ (configuration ? configuration + '.search.results.head' : 'search.results.head') | translate }}</h2>
  </div>
  <div class="ml-2">
    <ds-atmire-selective-export-dropdown [totalResults]="searchResults?.payload?.totalElements"
                                         [searchConfig]="searchConfig">
    </ds-atmire-selective-export-dropdown>
  </div>
</div>
<h2 *ngIf="!disableHeader">{{ (configuration ? configuration + '.search.results.head' : 'search.results.head') | translate }}</h2>
<div *ngIf="searchResults && searchResults?.hasSucceeded && !searchResults?.isLoading && searchResults?.payload?.page.length > 0" @fadeIn>
    <ds-viewable-collection
            [config]="searchConfig.pagination"
            [sortConfig]="searchConfig.sort"
            [objects]="searchResults"
            [hideGear]="true"
            [selectable]="selectable"
            [selectionConfig]="selectionConfig"
            [linkType]="linkType"
            [context]="context"
            [hidePaginationDetail]="hidePaginationDetail"
            (deselectObject)="deselectObject.emit($event)"
            (selectObject)="selectObject.emit($event)"
            [viewModeString]="viewModeString">
    </ds-viewable-collection>
</div>
<ds-themed-loading *ngIf="isLoading()" message="{{'loading.search-results' | translate}}"></ds-themed-loading>
<ds-error
        *ngIf="showError()"
        message="{{errorMessageLabel() | translate}}"></ds-error>
<div *ngIf="searchResults?.payload?.page.length == 0 || searchResults?.statusCode == 400">
    {{ 'search.results.no-results' | translate }}
    <a [routerLink]="['/search']"
       [queryParams]="{ query: surroundStringWithQuotes(searchConfig?.query) }"
       queryParamsHandling="merge">
        {{"search.results.no-results-link" | translate}}
    </a>
</div>
