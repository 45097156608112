import { Component } from '@angular/core';
import { ItemComponent } from '../../../../item-page/simple/item-types/shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import {Metadata} from '../../../../core/shared/metadata.utils';

@listableObjectComponent('Series', ViewMode.StandalonePage)
@Component({
  selector: 'ds-series',
  styleUrls: ['./series.component.scss'],
  templateUrl: './series.component.html'
})
/**
 * The component for displaying metadata and relations of an item of the type Project
 */
export class SeriesComponent extends ItemComponent {




  firstMetadataValue(keyOrKeys: string | string[]): string {
    return Metadata.firstValue([this.object.metadata], keyOrKeys);
  }
}
