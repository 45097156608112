import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ds-stl-viewer',
  styleUrls: ['./stl-viewer.component.scss'],
  templateUrl: './stl-viewer.component.html'
})

export class StlViewerComponent implements OnInit {
  @Input()
  href: string;

  ngOnInit() {
    const iframe = document.getElementById('stl-viewer-iframe');
    const iframedoc = (iframe as HTMLIFrameElement).contentDocument || (iframe as HTMLIFrameElement).contentWindow.document;
    iframedoc.open();
    iframedoc.write('\<div id="loading" style="margin: 235px 0 0 350px;">Loading ...\<\/div>');
    iframedoc.write('\<div id="stl-viewer" style="width:804px;height:532px;">\<\/div>');
    iframedoc.write('\<script src="/assets/images/stl_viewer.min.js">\<\/script>');
    iframedoc.write('\<script type="text/javascript"> var stl_viewer=new StlViewer(document.getElementById("stl-viewer"),' +
      '{' +
      '    models:' +
      '    [' +
      '        {filename:"' + this.href + '"}' +
      '    ],' +
      '    all_loaded_callback: () => {document.getElementById("loading").remove()}' +
      '});' +
      '\<\/script>');
    iframedoc.close();
    iframedoc.getElementsByTagName('html')[0].style.overflow = 'hidden';
    iframedoc.getElementsByTagName('body')[0].style.margin = '0';
  }

}
